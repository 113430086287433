import { Carousel, Drawer, Radio, RadioChangeEvent } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultPic from "../../../assets/nophoto.png";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { handleNextStepAction } from "../../device_financing/Subscription/redux/layout/actions";
import { getAllPlansAction } from "../../device_financing/Subscription/redux/planManagement/actions";
import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { ProgressInfobar } from "../components/ProgresssInfoBar";
import { getSpecificationValue } from "../../../utils/converter";
import { CurstomeCardSkeleton } from "../../../components/skeleton/Card";
import { LuAlignVerticalDistributeCenter } from "react-icons/lu";
const AllPlans = () => {
  const { auth, planManagement, wareHouse } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [value, setValue] = useState(1);
  const [selectePlan, setselectePlan] = useState("monthly");
  const channelId = auth?.user?.shop?.channel || auth?.userShop?.channelId;

  useEffect(() => {
    getAllPlansAction(
      auth?.token,
      `?&channel=${channelId}`
    )(dispatch);
  }, [auth?.token, dispatch, channelId]);
  
  return (
    <div>
      <div className="w-full px-6 pt-4 ">
        <h1 className="text-base sm:text-xl font-medium mb-2 2xl:mb-6">
          Available devices
        </h1>
          {/* <div className="flex-1 h-full "> */}
            {planManagement?.isFetching ? (
              <div className="grid md:grid-cols-2 lg:grid-cols-5">
                {[1, 2, 3, 1, 2, 3]?.map((el: any) => (
                  <CurstomeCardSkeleton />
                ))}
              </div>
            ) : (
              <div className="grid md:grid-cols-2 2xl:grid-cols-4 gap-4">
                {planManagement?.all?.data
                  ?.filter((el: any) => el?.duration)
                  ?.map((plan: any) => {
                    const capacity = getSpecificationValue(
                      plan?.pricelistItem?.specification,
                      ["capacity (rom)", "rom", "capacity"]
                    );
                    const memory = getSpecificationValue(
                      plan?.pricelistItem?.specification,
                      ["memory", "memory (ram)", "ram", "memory(ram)"]
                    );

                    const price =
                      selectePlan === "daily"
                        ? plan?.dailyInstalment
                        : selectePlan === "weekly"
                        ? plan?.weeklyInstalment
                        : plan?.monthlyInstalment;

                    return (
                      <div className="border-2 rounded-xl shadow p-6 text-center bg-white ">
                        <div className="flex items-center gap-x-3">
                          <div style={{ width: "6rem" }}>
                            <Carousel autoplay arrows>
                              {plan?.pricelistItem?.product?.images?.length >
                              0 ? (
                                plan?.pricelistItem?.product?.images?.map(
                                  (el: any) => (
                                    <img
                                      src={el}
                                      alt={`images`}
                                      className="w-24 h-24 object-cover"
                                    />
                                  )
                                )
                              ) : (
                                <img
                                  src={defaultPic}
                                  alt={`images`}
                                  className="w-24 h-24 object-cover"
                                />
                              )}
                            </Carousel>
                          </div>

                          <div className="text-start">
                            <h1 className="text-base sm:text-xl font-semibold mb-2">
                              {plan?.pricelistItem?.product?.model}
                            </h1>
                            <div className="text-left">
                              <p className="text-sm text-gray-500 capitalize">
                                <span className="text-black text-sm sm:text-base">Brand: </span>{" "}
                                {plan?.pricelistItem?.product?.brand}
                              </p>
                              <p className="text-sm text-gray-500">
                                <span className="text-black text-sm sm:text-base">
                                  Capacity(ROM):{" "}
                                </span>{" "}
                                {capacity}
                              </p>
                              <p className="text-sm text-gray-500 mb-4">
                                <span className="text-black text-sm sm:text-base">
                                  Memory(RAM):{" "}
                                </span>{" "}
                                {memory}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="text-left mb-4">
                          <h4 className="text-sm font-medium mb-2">
                            Includes:
                          </h4>
                          <ul className="space-y-2">
                            <li className="flex items-center">
                              <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                                <svg
                                  className="flex-shrink-0 w-3 h-3 text-blue-500 "
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 12"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5.917 5.724 10.5 15 1.5"
                                  />
                                </svg>
                              </div>
                              <p
                                className={`${
                                  plan?.bundle?.at(0) === undefined &&
                                  "line-through"
                                } text-sm text-gray-700`}
                              >
                                Bundles:{" "}
                                {`${plan?.bundle?.at(0) || "0"}  / Per Month`}
                              </p>
                            </li>
                            <li className="flex items-center">
                              <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                                <svg
                                  className="flex-shrink-0 w-3 h-3 text-blue-500 "
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 12"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5.917 5.724 10.5 15 1.5"
                                  />
                                </svg>
                              </div>
                              <p
                                className={`${
                                  plan?.bundle?.at(0) === undefined &&
                                  "line-through"
                                } text-sm text-gray-700`}
                              >
                                SMS: {`${plan?.sms?.at(0) || "0"} / Per Month`}
                              </p>
                            </li>
                            <li className="flex items-center">
                              <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                                <svg
                                  className="flex-shrink-0 w-3 h-3 text-blue-500 "
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 12"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5.917 5.724 10.5 15 1.5"
                                  />
                                </svg>
                              </div>
                              <p
                                className={`${
                                  plan?.bundle?.at(0) === undefined &&
                                  "line-through"
                                } text-sm text-gray-700`}
                              >
                                Airtimes:{" "}
                                {`${plan?.call?.at(0) || "0"} / Per Month`}
                              </p>
                            </li>
                          </ul>
                        </div>

                        <div className="grid grid-cols-2 text-start xitems-baseline mb-4">
                          <div className="pl-2">
                            <p className="text-base sm:text-xl font-semibold">{`${plan?.initialPayment?.toLocaleString()} Rwf`}</p>
                            <span >Upfront</span>
                          </div>
                          <div className="border-r pr-1">
                            <p className="text-base sm:text-xl font-semibold">{`${price?.toLocaleString()} Rwf`}</p>
                            <span className="capitalize">{`/${selectePlan}`}</span>
                          </div>
                        </div>
                        <p className="text-sm text-gray-500 mb-4 text-start">
                          <span className="font-semibold pr-2 text-sm sm:text-base">{`${
                            plan?.duration ?? "N/A"
                          } Months`}</span>
                          Duration
                        </p>
                        <p className="text-base sm:text-xl mb-4 text-start">
                          <span>Bussiness Product:</span>
                         <span  className="font-semibold pr-2 text-sm sm:text-base"> {`${
                              plan?.businessProductType ?? "N/A"
                          }`}</span>
                        </p>
{/* 
                        <LoadingButton
                          loading={
                            wareHouse?.isUpdateFetching &&
                            loadingPlanId === plan?._id
                          }
                          disabled={wareHouse?.isUpdateFetching}
                          sx={{
                            height: 40,
                            background: "#f0efff",
                            color: "blue",
                          }}
                          className="mt-4 text-sm sm:text-base w-48 transition"
                          onClick={() => {
                            handleChoosePlan(plan);
                          }}
                        >
                          Choose plan
                        </LoadingButton> */}
                      </div>
                    );
                  })}
              </div>
            )}
          {/* </div> */}
        </div>
    </div>
  );
};

export default AllPlans;
