import React, { useEffect, useState, useRef } from "react";
import { Form, Modal, notification, Upload, UploadFile } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import PhotoPLaceholder from "../../../../../assets/images/PhotoPLaceholder.png";
import Webcam, { WebcamProps } from "react-webcam";
import {
  customerDocumentsAction,
  updateCustomerAction,
} from "../../redux/customer/actions";
import { Progressbar } from "../Progressbar";
import { CiSquarePlus } from "react-icons/ci";
import { handleNextStepAction } from "../../redux/layout/actions";
import { subCustomerActions } from "../../redux/customer";
import { getShopStockCartAction } from "../../../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";

const { Dragger } = Upload;

const UploadDocs = () => {
  const { wareHouse, auth, subCustomer } = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const webcamRef = useRef<Webcam | null>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [choosenDoc, setChoosenDoc] = useState("");
  const [addDocs, setAddDocs] = useState(false);

  const hamdleAddOtherDocs = () => {
    setAddDocs(true);
  };

  const addressData =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const witnessInfoData =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const incomeFrequency =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.incomeFrequency;
  const mainIncomeSource =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.mainIncomeSource;
  const monthlyIncome =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.monthlyIncome;
  const ownsSmartphone =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.ownsSmartphone;
  const occupationOrBusinessType =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.occupationOrBusinessType;

  const onFinish = async (values: any) => {
    const payload = {
      ...values,
      subscriptionInfo: {
        address: addressData,
        incomeFrequency:incomeFrequency,
        mainIncomeSource:mainIncomeSource,
        monthlyIncome:monthlyIncome,
        ownsSmartphone:ownsSmartphone,
        occupationOrBusinessType:occupationOrBusinessType,
        witnessInfo: witnessInfoData,
        nationalIDDoc:
          subCustomer?.customerNationaID !== null
            ? subCustomer?.customerNationaID?.data?.secure_url
            : nationalId,
        photoCustomerHoldingID:
          subCustomer?.customerImage !== null
            ? subCustomer?.customerImage?.data?.secure_url
            : userImage,
      },
    };
    if (
      (subCustomer?.customerNationaID === null && !nationalId) ||
      (subCustomer?.customerImage === null && !userImage)
    ) {
      return notification.error({ message: "Please upload all documents" });
    }
    if (wareHouse?.createdCart?.data?.customer?._id) {
      const res = await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        payload
      )(dispatch);
      if (res) {
        await getShopStockCartAction(auth?.token, cartId)(dispatch);
        await handleNextStepAction(3)(dispatch);
        dispatch(subCustomerActions.setCustomerNationaID(null));
        dispatch(subCustomerActions.setCustomerImage(null));
      }
    }
  };

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      );
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer?.subscriptionInfo]);

  const initialData = {
    nationalIDDoc: nationalId,
  };

  const videoConstraints: WebcamProps["videoConstraints"] = {
    facingMode: "user",
  };

  const openCamera = async (values: any) => {
    setChoosenDoc(values);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });
      setMediaStream(stream);
      setIsCameraOpen(true);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const closeCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    setIsCameraOpen(false);
  };

  const captureImage = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        customerDocumentsAction(
          auth?.token,
          {
            image: imageSrc,
          },
          choosenDoc
        )(dispatch);
      }
    }
    setIsCameraOpen(false);
    closeCamera();
  };

  useEffect(() => {
    !isCameraOpen && setIsModalOpen(false);
  }, [isCameraOpen]);

  useEffect(() => {
    !isModalOpen && setMediaStream(null);
  }, [isModalOpen]);

  const beforeUpload = () => {
    return false;
  };

  const handlePrevious = () => {
    handleNextStepAction(1)(dispatch);
  };

  return (
    <div className="">
      <Progressbar name={"Documents"} percentage={50} />
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialData || {}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="flex justify-start  flex-col"
      >
        <div className="p-4 h-[27rem] 2xl:h-[calc(100vh-300px)] overflow-y-auto flex flex-col xbg-red-100">
          <div className="flex-1 flex flex-col space-y-4">
            <div className="rounded-lg space-y-2">
              <Form.Item
                name="CustomerProfile"
                className="2xl:max-2xl:h-24 2xl:max-2xl:-mt-1"
              >
                <div className="grid grid-cols-2 2xl:flex gap-4 ">
                  <div onClick={() => openCamera("image")}>
                    <h1 className="text-sm pb-2 font-semibold">Upload Image</h1>
                    <Dragger
                      openFileDialogOnClick={false}
                      beforeUpload={beforeUpload}
                      showUploadList={false}
                    >
                      {subCustomer?.customerImage || userImage ? (
                        <img
                          src={
                            subCustomer?.customerImage?.data?.secure_url ||
                            userImage
                          }
                          alt="Captured"
                          className="h-[150px] w-full 2xl:w-[330px] flex object-contain"
                        />
                      ) : (
                        <>
                          <p className="ant-upload-drag-icon flex items-center mx-auto justify-center">
                            <img
                              src={PhotoPLaceholder}
                              alt=""
                              className="w-20"
                            />
                          </p>
                          <p className="ant-upload-text font-medium text-gray-300">
                            Drag and drop your picture here, or{" "}
                            <span className="text-blue-400">
                              click to upload.
                            </span>
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                  <div onClick={() => openCamera("nid")}>
                    <h1 className="text-sm pb-2 font-semibold">
                      Upload National ID
                    </h1>
                    <Dragger
                      openFileDialogOnClick={false}
                      beforeUpload={beforeUpload}
                      showUploadList={false}
                    >
                      {subCustomer?.customerNationaID || nationalId ? (
                        <img
                          src={
                            subCustomer?.customerNationaID?.data?.secure_url ||
                            nationalId
                          }
                          alt="Captured"
                          className="h-[150px] w-full 2xl:w-[330px] flex object-contain"
                        />
                      ) : (
                        <>
                          <p className="ant-upload-drag-icon flex items-center mx-auto justify-center">
                            <img
                              src={PhotoPLaceholder}
                              alt=""
                              className="w-20"
                            />
                          </p>
                          <p className="ant-upload-text font-medium text-gray-300">
                            Drag and drop your ID image here, or{" "}
                            <span className="text-blue-400">
                              click to upload.
                            </span>
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                </div>
              </Form.Item>
            </div>

            <div>
              <div
                className="flex space-x-2 items-center cursor-pointer pb-3"
                onClick={hamdleAddOtherDocs}
              >
                <CiSquarePlus size={"30px"} color="blue" />
                <span className="text-lg font-medium">Other documents</span>
              </div>

              {addDocs && (
                <Upload
                  action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                  listType="picture-card"
                >
                  {fileList.length < 5 && "+ Upload"}
                </Upload>
              )}
            </div>
          </div>

          <div className="flex justify-between space-x-4">
            <LoadingButton
              variant="outlined"
              style={{
                width: "150px",
                height: 40,
              }}
              onClick={handlePrevious}
            >
              {`Previous`}
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "7px",
                height: 40,
              }}
              loading={subCustomer?.isFetching || wareHouse?.isReFetchingCart}
              disabled={subCustomer?.isFetching || wareHouse?.isReFetchingCart}
              className="w-[150px] h-[45px] text-lg uppercase"
            >
              {"Next"}
            </LoadingButton>
          </div>
        </div>
      </Form>

      <Modal title="National ID" open={isModalOpen} footer={null}>
        {isCameraOpen && (
          <>
            <div className="camera-container">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
            <div className="  items-center justify-between pt-2">
              <LoadingButton
                variant="contained"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderRadius: "2px",
                }}
                onClick={captureImage}
              >
                Save
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderColor: "red",
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={closeCamera}
              >
                Close
              </LoadingButton>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default UploadDocs;
