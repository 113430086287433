import React, { useState } from "react";
import CustomButton from "../../../../components/buttons/CustomButton";
import { Input } from "antd";
import CustomerDevicesTable from "../../../../components/tables/CustomerDevicesTable";
import CustomFilter from "../../../../components/filters/CustomFilter";
import CustomerProductTable from "../../../../components/tables/CustomerProductsTable";

const CustomerDevices = () => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const { Search } = Input;
  const onSearch = (value:any, _e:any, info:any) =>
    console.log(info?.source, value);
  const dataStatus = [
    {
      value: "",
      label: "All Devices(4)",
    },
    {
      value: "Locked(1)",
      label: "locked",
    },
    {
      value: "UnLocked(3)",
      label: "B2C Prepaid",
    },
  ];
  return (
    <div>
      <div className="flex justify-between">
        <CustomFilter setSelectedStatus={setSelectedStatus} data={dataStatus} />
        <div className="flex gap-5 h-8">
        <Search
              placeholder="Search Devices By IMEI"
              // onSearch={onSearch}
              style={{ width: "250px" }}
            />
        {/* <CustomButton title="Unlock All" textColor={'white'} bgColor={'[#0FA958]'}/>
        <CustomButton title="Lock All" textColor={'white'} bgColor={'[#F1595C]'}/> */}

        </div>
      </div>
      {/* <CustomerDevicesTable /> */}
      <CustomerProductTable/>
    </div>
  );
};

export default CustomerDevices;
