import { Button, Form, Input, notification, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined } from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";
import { PlusOutlined } from "@ant-design/icons";
import UploadAny from "../../../../components/forms/UploadAny";
import { getActiveShop, getTotalPaidAmount } from "../../../../utils/converter";
import { updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";
// import { paymentOptions } from "../../../../assets/data/comment";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const PaymentSection: React.FC<{
  paymentType: any;
  customerPay: number;
  isMacyeMacye: any;
  setCustomerPay: any;
  setPaymentType: any;
}> = ({
  paymentType,
  customerPay,
  isMacyeMacye,
  setCustomerPay,
  setPaymentType,
}) => {
  const { auth, wareHouse, appSettings } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { cartId } = useParams();

  const definedPaymentMethod = wareHouse?.allPaymentMethod?.data;
  const subPaymentMethode = wareHouse?.allPaymentMethod?.data?.flatMap(
    (el: any) =>
      el?.subPaymentType?.map((d: any) => {
        return { value: d, label: d };
      })
  );

  const [intelligraProof, setIntelligraProof] = useState<any[]>([]);
  const [vubavubaProf, setvubavubaProof] = useState<any[]>([]);

  const paidAmount = getTotalPaidAmount(wareHouse?.createdCart?.data?.payment);

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  const onFinish = async (values: any) => {
    // if([].includes(paymentType))
    const updatedPayment = values.payment.map((item: any) => ({
      ...item,
      amount:
        paymentType === "Intelligra_Insurance" ? customerPay : +item?.amount,
      reference:
        vubavubaProf?.length > 0
          ? [
              ...vubavubaProf?.map(
                (image: any) => image?.response?.data?.secure_url
              ),
            ]
          : [
              ...intelligraProof?.map(
                (image: any) => image?.response?.data?.secure_url
              ),
            ],
    }));

    if (
      updatedPayment[0]?.mode === "Vuba_Vuba" &&
      !updatedPayment[0]?.reference
    ) {
      return notification.warning({ message: "Upload Delivery note" });
    }

    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        payment: updatedPayment,
      })(dispatch));
  };

  const onchangeIntelligraAmount = (e: any) => {
    setCustomerPay(
      wareHouse?.createdCart?.data?.list[0]?.payment?.amount - e.target.value
    );
  };

  const handlePaymentType = (value: any) => {
    setPaymentType(value);
  };

  const filteredOptions = definedPaymentMethod?.filter((option: any) => {
    if (wareHouse?.createdCart?.data?.isSubscriptionSubmitted) {
      return (
        option.name !== "Macye_Macye" &&
        option.name !== "Vuba_Vuba" &&
        option.name !== "Corporate_Sales" &&
        option.name !== "Intelligra_Insurance" &&
        option.name !== "Yello_Payment" &&
        option.name !== "Samphone_Staff_Handset_Requisition"
      );
    }
    return true;
  });

  return (
    <div className="relative pb-4 border p-2 rounded-lg">
      <h3 className="mt-2 font-semibold my-3">Payment Method</h3>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        // initialValues={props.data || {}}
        // style={{ minWidth: 600 }}
        scrollToFirstError
        className="lg:max-w-[600px]"
      >
        <Form.List
          name="payment"
          {...formItemLayout}
          initialValue={[...wareHouse?.createdCart?.data?.payment]}
          // initialValue={wareHouse?.createdCart?.data?.payment.map(
          //   (item: object) => ({
          //     ...item,
          //     reference:
          //       // vubavubaProf[0]?.response?.data?.secure_url ||
          //       "hi!...", // Add the "reference" field with an initial value
          //   })
          // )}
        >
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="flex flex-col pb-1 border-b mb-3">
                    <div className="flex items-center">
                      <Form.Item
                        {...restField}
                        name={[name, "mode"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing names",
                          },
                        ]}
                        // style={{ minWidth: 250 }}
                        className="mb-1 mr-3"
                      >
                        <Select
                          onChange={handlePaymentType}
                          placeholder="Select a payment method"
                          // className="border border-[#03022940] rounded-md py-2"
                          optionFilterProp="children"
                          // onChange={onChange}
                          // onSearch={onSearch}
                          style={{ width: 200 }}
                          // options={filteredOptions}
                        >
                          {filteredOptions?.map((el: any) => (
                            <Select.Option key={el?._id} value={el?.name}>
                              {el?.name?.split("_")?.join(" ")}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <button
                        onClick={() => remove(name)}
                        className="flex rounded-md shadow gap-x-2 py-2 w-fit px-3 border text-red-500"
                      >
                        <MinusCircleOutlined className=" my-auto" />
                        {/* <p>Remove method</p> */}
                      </button>
                    </div>

                    {[
                      "Macye_Macye",
                      "Intelligra_Insurance",
                      "Corporate_Sales",
                      "Vuba_Vuba",
                      "Yello_Payment",
                      "Samphone_Staff_Handset_Requisition",
                    ]?.includes(paymentType) && (
                      <Form.Item
                        {...restField}
                        name={[name, "macyemacyeType"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing payment type",
                          },
                        ]}
                        className="w-full mb-1"
                      >
                        <Select
                          placeholder="Select a payment method"
                          optionFilterProp="children"
                          className="py-2"
                          style={{ width: 200 }}
                          options={subPaymentMethode}
                        />
                      </Form.Item>
                    )}

                    {paymentType !== "Intelligra_Insurance" && (
                      <Form.Item
                        {...restField}
                        name={[name, "amount"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing amount",
                          },
                        ]}
                        className="w-full mb-1 font-medium text-[#030229]"
                      >
                        <Input
                          placeholder={"Amount"}
                          type="number"
                          width={"100%"}
                        />
                      </Form.Item>
                    )}

                    {paymentType === "Intelligra_Insurance" && (
                      <Form.Item
                        {...restField}
                        name={[name, "intelligraAmount"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing amount",
                          },
                        ]}
                        className="w-full mb-1"
                      >
                        <Input
                          placeholder={"Intelligra Amount"}
                          type="number"
                          width={"100%"}
                          onChange={onchangeIntelligraAmount}
                        />
                      </Form.Item>
                    )}

                    {["Intelligra_Insurance"]?.includes(paymentType) && (
                      <div>
                        <label className="block text-sm font-medium text-gray-900">
                          Customer Pay
                        </label>

                        <input
                          value={customerPay}
                          type="text"
                          id="first_name"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:outline-none focus:border-gray-300 block w-[67%] py-1.5 px-2 cursor-not-allowed mb-1"
                          placeholder="Customer Pay"
                          required
                        />
                      </div>
                    )}

                    {process.env.REACT_APP_ShopId_with_Momo_Implementation?.includes(
                      shopId
                    )
                      ? ![
                          "Macye_Macye",
                          "Vuba_Vuba",
                          "MoMo_Pay",
                          "Yello_Payment",
                          "Samphone_Staff_Handset_Requisition",
                        ].includes(paymentType) && (
                          <Form.Item
                            {...restField}
                            name={[name, "momoTransactionId"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Transaction Id",
                              },
                            ]}
                            className="w-full mb-1"
                          >
                            <Input placeholder="Transaction Id" type="text" />
                          </Form.Item>
                        )
                      : ![
                          "Macye_Macye",
                          "Vuba_Vuba",
                          "Yello_Payment",
                          "Samphone_Staff_Handset_Requisition",
                        ].includes(paymentType) && (
                          <Form.Item
                            {...restField}
                            name={[name, "momoTransactionId"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Transaction Id",
                              },
                            ]}
                            className="w-full mb-1"
                          >
                            <Input placeholder="Transaction Id" type="text" />
                          </Form.Item>
                        )}

                    {[
                      "Vuba_Vuba",
                      "Corporate_Sales",
                      "MTN_Bank_Deposit",
                      "Samphone_Bank_Deposit",
                      "MTN_POS",
                      "Samphone_POS",
                    ].includes(paymentType) && (
                      <div className="items-center w-full gap-2">
                        <p className="min-w-max">Delivery Note / Proof</p>
                        <Form.Item
                          {...restField}
                          name={[name, "reference"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing documents",
                            },
                          ]}
                        >
                          <div className="w-full">
                            <UploadAny
                              setPictures={setvubavubaProof}
                              limit={5}
                              isCard
                              // default={
                              //   wareHouse?.createdCart?.data
                              //     ?.payment[0]?.reference
                              // }
                            />
                          </div>
                        </Form.Item>
                      </div>
                    )}

                    {["Intelligra_Insurance"].includes(
                      paymentType ||
                        wareHouse?.createdCart?.data?.payment[0]?.mode
                    ) && (
                      <div className="items-center w-full gap-2 pt-2">
                        <p className="min-w-max font-semibold">
                          Intelligra Proof
                        </p>
                        <Form.Item
                          {...restField}
                          name={[name, "reference"]}
                          rules={[
                            {
                              required: true,
                              message: "Intelligra Proof is required",
                            },
                          ]}
                        >
                          <div className="w-full">
                            <UploadAny
                              setPictures={setIntelligraProof}
                              limit={2}
                              isCard
                              // default={
                              //   wareHouse?.createdCart?.data
                              //     ?.payment[0]?.reference
                              // }
                            />
                          </div>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                ))}

                <Form.Item className="absolute top-3 right-3">
                  <Button
                    onClick={() => add()}
                    // startIcon={<PlusOutlined />}
                    disabled={isMacyeMacye}
                    className="shadow !m-0 justify-between !items-center"
                  >
                    {/* Add Method */}
                    <PlusOutlined />
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <div className="flex items-center justify-end gap-2 max-w-[500px]">
          <p>
            <span>Total paid</span>
          </p>
          <span className="text-right font-bold text-neutral-800 ">{`${
            paidAmount?.toLocaleString() || 0
          } RWF`}</span>
        </div>
        <Form.Item>
          {/* <div className="flex"> */}
          <LoadingButton
            type="submit"
            variant="contained"
            loading={wareHouse?.isUpdateFetching}
          >
            Confirm payments
          </LoadingButton>
          {/* </div> */}
        </Form.Item>
      </Form>
    </div>
  );
};

export default PaymentSection;
