import { SERVER_URL } from "../../../../../utils/constants";
import HttpRequest from "../../../../../utils/HttpRequest";

export const createServiceCustomer = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/customer`, data, token);
};
export const customerDocumentsService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/product/upload`, data, token);
};
export const getCustomFormService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/customerForm${query}`, token);
};
