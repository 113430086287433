import React, { useState } from "react";
import {
  FormSkeleton,
  FoundItemSkeleton,
} from "../../../../../components/skeleton/Card";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import SearchBar from "../../../../../components/SearchBar";
import BarcodeScannerModal from "../../../../../components/BarcodeScannerModal";
import ProductCard from "../../../../../pages/dashboard/Sales/productCard";
import { getActivePrice, getActiveShop } from "../../../../../utils/converter";
import {
  getAllShopStockAction,
  updateShopStockCartAction,
  updateWareHouseDetailsAction,
} from "../../../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";
import { myWareHouseActions } from "../../../../../store/wareHouse";
import CustomerDetails from "../CustomerDetails";
import { createAccountItemAction } from "../../../../../store/account/actions";

const DeviceScanning = () => {
  const { auth, wareHouse, customer, appSettings } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const [scannedCode, setScannedCode] = useState<string>();
  const isFound =
    wareHouse?.searchResult?.status === 200 &&
    wareHouse?.searchResult?.data?.isInShop &&
    wareHouse?.searchResult?.data?.product?.status !== "sold";

  const plan = wareHouse?.createdCart?.data?.selectedPlan;

  const isCartEmpty = wareHouse?.createdCart?.data?.list?.length < 1;
  const isSubscription =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";

  const productData = wareHouse?.searchResult;

  const onFinish = async (values: any) => {
    setIsSearching(true);
    try {
      auth?.token &&
        (await getAllShopStockAction(
          auth?.token,
          `?serialNumber=${values?.query}&shopId=${
            getActiveShop(auth?.user?.shop?.assigned)[
              appSettings?.selectedShopNumber
            ]?.shop?._id ?? auth?.userShop?.shopId
          }&subscriptionPlanId=${plan?._id}&cartId=${cartId}`
        )(dispatch));
    } catch (error) {
      console.log(error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleChange = (e: any) => {
    setNewSearch(true);
  };
  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };

  const initialPayment = plan?.initialPayment;

  const handleAddToCartInfo = async () => {
    const product = wareHouse?.searchResult?.data?.product;
    const payload = {
      cart: cartId,
      shopStockItem: product?._id,
      warehouseProduct: product?.wareHouseItem?._id,
      channel: product?.shop?.channel?._id,
      shop: product?.shop?._id,
      priceProduct: product?.requestedItem?.product?._id,
      subscriptionStatus:"DEVICE_ADDED",
      payment: {
        amount: isSubscription
          ? initialPayment
          : +getActivePrice(product?.requestedItem?.product?.prices),
        extendedWarranty: 0,
        installementPayment: `${
          wareHouse?.createdCart?.data?.instalmentPlan === "daily"
            ? plan.dailyInstalment
            : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
            ? plan.weeklyInstalment
            : plan.monthlyInstalment || 0
        }`,
      },
    };
    try {
      auth?.token &&
        (await updateShopStockCartAction(
          auth?.token,
          cartId,
          payload
        )(dispatch));
      dispatch(myWareHouseActions.setSearchResult(null));
      await updateWareHouseDetailsAction(
        auth?.token,
        product?.wareHouseItem?._id,
        {
          dataActivated: false,
        }
      )(dispatch);
      await createAccountItemAction(cartId,{},auth?.token)(dispatch)
    } catch (e) {
    } finally {
    }
  };
  return (
    <div className=" h-full sm:h-[80vh]">
      {["pending", "requested"].includes(
        wareHouse?.createdCart?.data?.status
      ) ? (
        ""
      ) : (
        <div className="h-full sm:h-[85%] md:h-[48.5%] bg-white pb-20 sm:pb-0 shadow-md rounded-md p-5 w-full ">
          {wareHouse.isFetching && !isSearching ? (
            <FoundItemSkeleton />
          ) : (
            <>
              {!isFound ? (
                <div className="flex justify-center gap-x-2">
                  <Form onFinish={onFinish}>
                    <Form.Item
                      name="query"
                      className="rounded-lg flex"
                      // label="Enter serial number"
                      initialValue={scannedCode}
                    >
                      <SearchBar
                        isLoading={isSearching}
                        scannedCode={newSearch ? "" : scannedCode}
                        onChange={handleChange}
                        isDisabled={!isCartEmpty && isSubscription}
                      />
                    </Form.Item>
                    {!wareHouse?.isFetching &&
                      wareHouse?.searchError?.status === 400 && (
                        <p className="font-semibold text-red-500">
                          {wareHouse?.searchError?.data?.error}
                        </p>
                      )}

                    <div className="py-1">
                      {!wareHouse?.isFetching &&
                        wareHouse?.searchResult?.status === 200 &&
                        wareHouse?.searchResult?.data?.isInShop &&
                        wareHouse?.searchResult?.data?.product?.status !==
                          "sold" && (
                          <p className="font-semibold text-green-500">
                            Product found
                          </p>
                        )}
                      {!wareHouse?.isFetching &&
                        wareHouse?.searchError?.status === 404 && (
                          <p className="font-semibold text-red-500">
                            Product not found
                          </p>
                        )}
                      {!wareHouse?.isFetching &&
                        !wareHouse?.searchResult?.data?.isInShop && (
                          <div className="font-semibold text-yellow-500">
                            {wareHouse?.searchResult?.message}
                          </div>
                        )}
                      {!wareHouse?.isFetching &&
                        wareHouse?.searchResult?.data?.isInShop &&
                        wareHouse?.searchResult?.data?.product?.status ===
                          "sold" && (
                          <div className="font-semibold text-yellow-500">
                            The product is sold
                          </div>
                        )}
                    </div>
                  </Form>
                  <BarcodeScannerModal
                    getCode={getCode}
                    isDisabled={!isCartEmpty && isSubscription}
                  />
                </div>
              ) : (
                <ProductCard onClick={handleAddToCartInfo} data={productData} />
              )}
            </>
          )}
        </div>
      )}

      <div
        className={`${
          ["pending", "requested"].includes(
            wareHouse?.createdCart?.data?.status
          )
            ? "h-[95%]"
            : "h-1/2"
        } bg-white shadow-md rounded-md p-5 w-full mt-2 text-[#030229]`}
      >
        {customer?.isFetching ? (
          <FormSkeleton />
        ) : (
          <div className="h-full bg-white p-2 rounded-lg overflow-y-auto">
            <CustomerDetails />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceScanning;
