import React from 'react'
import { CiEdit } from 'react-icons/ci';
import { useSelector } from 'react-redux';

const ApplicantsPlans = (props:any) => {
  const { auth, apllicants } = useSelector((state: any) => state);
  
  return (
    <div>
        <div className="border border-[#0302291A] p-4 mt-5 rounded-md w-full">
        <div className="flex justify-between">
          <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
            Plan Details
          </h1>
          <div
            className="bg-[#E5E5E5] rounded-md h-[26px] w-[56px] flex justify-center items-center gap-2 cursor-pointer"
            //   onClick={showReceiverModal}
          >
            <CiEdit size={15} />
            <p className="text-[12px]">Edit</p>
          </div>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm"> Device</p>
          <p className="text-[#030229] text-sm">
            {apllicants?.applicantDetails?.data?.selectedPlan?.pliceListItem?.product?.model}
          </p>
        </div>
        <div className="grid grid-cols-3 md:flex w-full gap-5 md:gap-10 mt-3">
        {apllicants?.applicantDetails?.data?.selectedPlan?.pricelistItem?.specification?.map(
            (el: any, index: number) => {
              return (
                <div key={index}>
                  <p className="text-[#03022980] text-sm">{el[0]}</p>
                  <p className="text-[#030229] text-sm">{el[1]}</p>
                </div>
              );
            }
          )}
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Installment Amount</p>
          <p className="text-[#030229] text-sm">
            RWF{" "}
            {apllicants?.applicantDetails?.data?.selectedPlan?.monthlyInstalment?.toLocaleString()}
          </p>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Plan Period</p>
          <p className="text-[#030229] text-sm ">
            {apllicants?.applicantDetails?.data?.selectedPlan?.duration} Months
          </p>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Installment Plan</p>
          <p className="text-[#030229] text-sm capitalize">
            {apllicants?.applicantDetails?.data?.instalmentPlan}
          </p>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Initial Payment</p>
          <p className="text-[#030229] text-sm">
            RWF{" "}
            {apllicants?.applicantDetails?.data?.selectedPlan?.initialPayment?.toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ApplicantsPlans