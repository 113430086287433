import { BrowserRouter } from "react-router-dom";
import MainRouter from "./routes/MainRouter";
import { ConfigProvider } from "antd";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import SplashScreen from "./components/layout/SplashScreen_02";
import { useSelector } from "react-redux";
// import socketIOClient from "socket.io-client";
// import { socketActions } from "./store/socket";
// import { SOCKET_SERVER_URL } from "./utils/constants";
// import AutoLogout from "./components/layout/AutoLogout";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4C39D4",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 12,
    fontWeightLight: 800,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {},
});

const App = () => {
  // const dispatch = useDispatch();
  const [showSplash, setShowSplash] = useState(true);
  // const [staticUser, setStaticUser] = useState<any>(null);
  const { auth } = useSelector((state: any) => state);

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
          } else {
          }
        });
      }
    }

    // ------ HIDDING ERROR : ResizeObserver loop completed with undelivered notifications.  -----------
    function hideError(e: any) {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    }

    window.addEventListener("error", hideError);
    return () => {
      window.addEventListener("error", hideError);
    };

    // <<<<------ HIDDING ERROR : ResizeObserver loop completed with undelivered notifications.  --->>>>>>
  }, []);

  // useEffect(() => {
  //   if (auth?.user === null) {
  //     window.location.reload();
  //   }
  // }, [auth?.user]);

  useEffect(() => {
    setTimeout(() => setShowSplash(false), 3000);
  }, []);

  const handleNewNotification = (notification: {
    message: string;
    names: string;
    action: string;
    createdAt: string;
  }) => {
    const newNotification = new Notification(`${notification.action}`, {
      body: notification.message,
      icon: "/Force_F_White.svg",
      requireInteraction: true,
    });
    newNotification.onclick = (event) => {
      event.preventDefault();
      window.open("http://force.samphone.co/dashboard", "_blank");
    };
    newNotification.removeEventListener("onClick", () => {});
  };

  // useEffect(() => {
  //   if (staticUser) {
  //     const socket = socketIOClient(`${SOCKET_SERVER_URL}`, {
  //       auth: {
  //         token: user._id,
  //       },
  //     });

  //     socket.on("connect", () => {
  //       console.log("connected");
  //     });

  //     socket.on("newNotification", (notification) => {
  //       handleNewNotification(notification);
  //     });

  //     socket.on("onlineUsers", (onlineUsers) => {
  //       dispatch(socketActions.setOnlineUser(onlineUsers)); //Changes dispatch state
  //     });

  //     dispatch(socketActions.storeSocket(socket));

  //     return () => {
  //       socket.disconnect();
  //     };
  //   }
  // }, [staticUser]);

  // useEffect(() => {
  //   if (user && user != staticUser) {
  //     setStaticUser(user);
  //   }
  // }, [user]);

  return (
    <BrowserRouter>
      {/* <AutoLogout/> */}
      {showSplash || (auth?.profileIsFetching && !auth?.isLoggingIn) ? (
        <SplashScreen />
      ) : (
        <ConfigProvider
          theme={{
            token: {
              fontSize: 12,
              colorPrimary: "#4C39D4",
              fontFamily: "'Poppins', sans-serif",
            },
            components: { Button: { colorBgElevated: "yellow" } },
          }}
        >
          <ThemeProvider theme={theme}>
            <div
              className="h-screen"
              style={{
                // backgroundImage: `url(${BgImage})`,
                backgroundColor: "#fafafb",
                backgroundRepeat: "no-repeat",
              }}
            >
              <MainRouter />
            </div>
            {/* <ResponseDialog /> */}
          </ThemeProvider>
        </ConfigProvider>
      )}
    </BrowserRouter>
  );
};

export default App;
