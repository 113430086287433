import React from "react";
import { Box, Stack } from "@mui/material";
import { Badge } from "antd";
import { useNavigate } from "react-router-dom";
import ProfileAvatal from "../buttons/ProfileAvatal";

const Component = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <Badge.Ribbon
        text={props.data?.status}
        color={props.data?.isActive ? "green" : "red"}
      >
        <div className="w-80 h-40 bg-white text-black rounded-lg p-2.5 border border-gray-100  hover:bg-white hover:border-gray-300">
          {/* <Checkbox {...label} color="success" /> */}
          <div onClick={() => navigate(`/customers/${props.data._id}`)}>
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
                <Stack direction={"row"} spacing={2}>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "4rem",
                      marginRight: "4px",
                    }}
                  >
                    <ProfileAvatal picture={props?.data?.picture} />
                  </div>
                </Stack>
                <Stack spacing={0}>
                  <h1 className="capitalize">{props.data?.name}</h1>
                  <p className="text-xs" style={{ color: "blue" }}>
                    {props.data?.nid}
                  </p>
                </Stack>
              </Stack>
              {/* <Divider color="warning"/> */}
              <Box sx={{ width: "100%" }}>
                <Stack
                  direction="row"
                  marginLeft={"20px"}
                  alignItems="flex-end"
                  sx={{ width: "100%" }}
                >
                  <Stack spacing={1}>
                    <p className="text-xs">
                      <b>Email: </b>
                      {props.data?.email}
                    </p>
                    <p className="text-xs">
                      <b>Phone: </b> {props.data?.phone}
                    </p>
                    <p className="text-xs">
                      <b> Address: </b>
                      {props.data?.address}
                    </p>
                    <p className="text-xs">
                      <b> Type: </b>
                     <span className="capitalize text-[12px]">{props.data?.subscriptionInfo?.customerType}</span> 
                    </p>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </div>
        </div>
      </Badge.Ribbon>
    </>
  );
};

export default Component;
