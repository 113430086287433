import React, { useState } from "react";
import ConfirmationQuestion from "./card/ConfirmationQuestion";
import { Divider, notification, RadioChangeEvent } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import {
  handlConfirmPaymentModelAction,
  handleConfirmFinalStageAction,
} from "../redux/layout/actions";

const FinalConfirmation = () => {
  const { auth } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    question1: null,
    question2: null,
    question3: null,
    question4: null,
    question5: null,
  });

  const onChange = (e: RadioChangeEvent, questionNumber: number) => {
    const response = e.target.value;
    const questionKey = `question${questionNumber}`;

    setFormData((prevData) => ({
      ...prevData,
      [questionKey]: response,
    }));
  };

  const isFormComplete = () => {
    return Object.values(formData).every(
      (response) => response !== null && response === "true"
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isFormComplete()) {
      handlConfirmPaymentModelAction(false)(dispatch);
     auth?.user?.profile==="freelancer"? navigate("/home"):navigate("/sale");
      handleConfirmFinalStageAction(false)(dispatch);
    } else {
      notification.error({
        message: "Kindly confirm once more that everything is complete.",
      });
    }
  };

  return (
    <div className="flex flex-col justify-center items-center ">
      <h2 className="text-center text-lg font-semibold">
        AGENT CHECKLIST FORM
      </h2>
      <Divider />
      <div className="bg-white w-full max-w-[39rem] pb-8">
        <h1 className="text-center mb-1 text-gray-800 text-lg font-semibold">
          {`Hi, ${auth?.user?.names}, Please fill out this checklist form.`}
        </h1>
        <h3 className="text-center mb-5 text-gray-800 text-lg font-normal">
          By answering the following questions.
        </h3>

        <div className="space-y-6">
          <ConfirmationQuestion
            question="I. Have you registered a customer personal details?"
            questionNumber={1}
            handleChange={onChange}
          />
          <ConfirmationQuestion
            question="II. Did customer sign consent agreements?"
            questionNumber={2}
            handleChange={onChange}
          />
          <ConfirmationQuestion
            question="III. Did customer agree with terms and condition?"
            questionNumber={3}
            handleChange={onChange}
          />
          <ConfirmationQuestion
            question="IV. Did you receive the customer first initial payment?"
            questionNumber={4}
            handleChange={onChange}
          />
          <ConfirmationQuestion
            question="V. Have you configured the vault?"
            questionNumber={5}
            handleChange={onChange}
          />

          <div className="text-center mt-8">
            <LoadingButton
              variant="contained"
              style={{ width: 200, height: 40, fontSize: "16px" }}
              onClick={handleSubmit}
            >
              SUBMIT
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalConfirmation;
