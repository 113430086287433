import React, { useEffect, useRef, useState } from "react";
import { getAllCartsAction } from "../../../store/pos/actions";
import { getActiveShop } from "../../../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { Spin, Input } from "antd";
import CartGridPOS from "../../../components/grids/POS/sale/CartGridPOS";
import {
  handleConfirmFinalStageAction,
  handleNextStepAction,
} from "../../../apps/device_financing/Subscription/redux/layout/actions";
import { myWareHouseActions } from "../../../store/wareHouse";
import { myLayoutActions } from "../../../store/layout";
import { SearchProps } from "antd/es/input";
import "../../../styles/button.css";

const { Search } = Input;

const SalesOverView = () => {
  const { auth, appSettings, pos,account } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [isMisisngApplicationId, setIsMissingApplicationID] = useState(false);
  const searchInputRef = useRef<any>(null);

  useEffect(() => {
    handleNextStepAction(0)(dispatch);
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myWareHouseActions.setSearchResult(null));
    handleConfirmFinalStageAction(false)(dispatch);
    dispatch(myLayoutActions.setSetConfirmCheckout(false));
  }, [dispatch]);

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  useEffect(() => {
    if (auth?.token && shopId)
      getAllCartsAction(
        auth?.token,
        `?shop=${shopId}&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry`
      )(dispatch);
  }, [dispatch, auth?.token, shopId]);

  const onSearch: SearchProps["onSearch"] = (value: any) => {
    setIsMissingApplicationID(false);
    if (value === "") {
      setIsMissingApplicationID(true);
    } else {
      console.log(":::::search result::::", value);
      // if (searchInputRef?.current) {
      //   searchInputRef.current.input.value = "";
      // }
    }
  };

  return (
    <div>
      {/* <div className="max-w-sm pb-2">
        <Search
          placeholder="Search Application ID"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={onSearch}
          ref={searchInputRef}
        />
        {isMisisngApplicationId && (
          <span className="text-red-500 text-xs pl-1">
            Please add Application ID
          </span>
        )}
      </div> */}
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={pos?.carts?.total}
        count={Math.ceil(pos?.carts?.total / limit)}
      >
        {pos?.isFetching || account?.isFetching ? (
          <div className="flex flex-col items-center justify-center mx-auto mt-32">
            <span>
              {" "}
              <Spin spinning />
            </span>
            <span className="ml-3 text-gray-500">Loading...</span>
          </div>
        ) : (
          <div className="flex flex-wrap gap-6 pb-3">{<CartGridPOS />}</div>
        )}
      </ScrollableFrame>
    </div>
  );
};

export default SalesOverView;