import React from "react";
import { useSelector } from "react-redux";
import { getActivePlan, getTotalPaidAmount } from "../../../../utils/converter";

const Checkout: React.FC<{ planType: string; getTotalPrice: any }> = ({
  planType,
  getTotalPrice,
}) => {
  const { wareHouse } = useSelector((state: any) => state);

  const itemsList = wareHouse?.createdCart?.data?.list;
  const plan = wareHouse?.createdCart?.data?.subscriptionPlan;
  const discount = wareHouse?.createdCart?.data?.discount;
  const paidAmount = getTotalPaidAmount(wareHouse?.createdCart?.data?.payment);

  const isSubscripitonPage =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";

  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
  );

  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const getTotalPrices = (list: any, subscritionPlan: number) => {
    return list
      ?.map(
        (item: any, _index: number) =>
          item?.payment?.amount +
          (item?.payment?.amount * item?.payment?.extendedWarranty) / 100
      )
      ?.reduce((total: any, price: any) => (total += parseInt(price)), 0);
  };

  return (
    <div className="flex justify-center border-t pt-2 ">
      <div className="w-full  gap-x-2">
        <div className="flex items-center justify-between">
          <p className="text-[#030229] text-base font-normal">Total: </p>
          <span className="text-right">{`${
            isSubscripitonPage
              ? `${
                  ActivePlan &&
                  ActivePlan.at(0)?.initialPayment?.toLocaleString()
                }`
              : getTotalPrices(itemsList, plan)?.toLocaleString()
          } RWF`}</span>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-[#030229] text-base font-normal">Discount:</p>
          <span className="text-right">{`(${discount?.discountPerc}%) ${(
            getTotalPrice(itemsList) *
            (discount?.discountPerc / 100)
          )?.toLocaleString()} RWF`}</span>
        </div>
        <div className="flex items-center justify-between">
          <p className="flex items-center text-[#030229] text-base font-normal mt-5">
            Paid amount:
          </p>
          <span className="text-right font-bold text-neutral-800 ">{`${
            paidAmount?.toLocaleString() || 0
          } RWF`}</span>
        </div>

        <div className="flex items-center justify-between">
          <p className="flex items-center text-[#030229] text-base font-normal mt-5">
            Final amount:
          </p>
          <span className={`text-right font-bold text-slate-800 mt-5`}>{`${
            isSubscripitonPage
              ? `${
                  ActivePlan &&
                  ActivePlan.at(0)?.initialPayment?.toLocaleString()
                }`
              : (
                  getTotalPrices(itemsList, plan) -
                  getTotalPrice(itemsList) * (discount?.discountPerc / 100)
                )?.toLocaleString()
          } RWF`}</span>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
