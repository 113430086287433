import React, { ReactInstance, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalPrice } from "../../../../utils/converter";
import { Divider } from "antd";
import { ReactComponent as SamphoneLogo } from "../../../../assets/icons/logo.svg";
import CartItemsTable from "../../../../components/tables/pos/CartItemsTable";
import RRAPaymentReceipt from "../RRAPaymentReceipt";
import { useReactToPrint } from "react-to-print";
import { getOneCompanyAction } from "../../../../store/company/actions";
import { COMPANY_PROFILE_ID } from "../../../../utils/constants";
import { getDefaultCurrencyCode } from "../../../../utils/CurrencyHelper";

const ConfirmCheckout = (props: any) => {
  const templateRef = useRef<ReactInstance>(null);
  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
  });

  const { wareHouse, shop, auth, company } = useSelector((state: any) => state);
  const itemsList = wareHouse?.createdCart?.data?.list;
  const dispatch = useDispatch();
  const amount = wareHouse?.createdCart?.data?.payment?.reduce(
    (curr: any, item: any) => curr + item?.amount,
    0
  );
  const discount = wareHouse?.createdCart?.data?.discount;

  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
  }, [auth.token, dispatch, companyId]);

  return (
    <div className="flex flex-wrap gap-x-2 rounded-lg text-[#030229] font-normal">
      <div className="flex flex-col w-full lg:w-[70%] gap-y-[0.7vw] py-[30px] px-5 rounded-lg text-[#0F0F47] vbg-red-200 shadow-md">
        <div>
          <div className="flex flex-wrap-reverse justify-between px-2 mb-2">
            <div className=" w-[30rem]">
              <p className=" font-medium mb-2 text-lg mt-3 lg:mt-0">Bill from : </p>
              <div className="flex flex-col gap-1 text-sm">
                <p> {company?.selected?.name}</p>
                <p>{company?.selected?.address?.provence} - {company?.selected?.address?.country}</p>
                <p>{company?.selected?.email}</p>
                <p> {company?.selected?.phone}</p>
                <p>{company?.selected?.tin}</p>
                <p>{company?.selected?.cachier}</p>
              </div>
            </div>
            <div>
              <img
                src={company?.selected?.logo}
                className="w-[10rem] h-[10rem]"
                alt=""
              />
            </div>
          </div>
          <Divider />
          <div className=" flex  flex-wrap justify-between space-x-1">
            <div className="flex flex-col ">
              <p className="font-medium mb-2 text-lg">Bill to : </p>
              <div className="flex flex-col gap-1 text-sm">
                <p>{wareHouse?.createdCart?.data?.customer?.name}</p>
                <p>{wareHouse?.createdCart?.data?.customer?.phone}</p>
                <p>{wareHouse?.createdCart?.data?.customer?.email}</p>
              </div>
            </div>
            <Divider className="block md:hidden" />
            <div>
              <div className="grid grid-cols-2 w-fit text-[#030229] text-sm">
                <p className="opacity-70">Invoice</p>
                <p>{wareHouse?.createdCart?.data?.cartId}</p>
                <p className="opacity-70">Date</p>
                <p>
                  {wareHouse?.createdCart?.data?.paidOn?.slice(0, 10)}
                </p>
                <p className="opacity-70">Shop</p>
                <p>{shop?.myShop?.name}</p>
                <p className="opacity-70 ">
                  Discount {discount?.discountPerc}%
                </p>
                <p>{`${(
                  getTotalPrice(itemsList) *
                  (discount?.discountPerc / 100)
                )?.toLocaleString()} RWF`}</p>
                <p className="font-medium bg-gray-200 px-1 rounded-sm p-1 mt-2 ">Amount Due</p>
                <span className="font-semibold bg-gray-200 p-1 mt-2 px-1 rounded-sm ">{`${amount?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="h-1/2 p-2 overflow-auto space-y-2">
          <CartItemsTable />
          <div className="flex justify-between space-x-2">
            <div>{/* here! date. */}</div>
            <div className="grid grid-cols-2 gap-x-2 text-right text-[#030229] font-semibold">
              {/* <p>Untaxed Amount:</p>
              <p>{`${getTotalPrice(itemsList)?.toLocaleString()} RWF`}</p> */}
              {/* <p>Discount {discount?.discountPerc}%:</p>
              <p>{`${(
                getTotalPrice(itemsList) *
                (discount?.discountPerc / 100)
              )?.toLocaleString()} RWF`}</p> */}
              {/* <p>VAT 0%:</p>
              <p>0.00 RWF</p>
              <p>Total:</p>07890
              <p>{`${(
                getTotalPrice(itemsList) -
                getTotalPrice(itemsList) * (discount?.discountPerc / 100)
              )?.toLocaleString()} RWF`}</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1">
        <div className="rounded-lg ">
          <RRAPaymentReceipt
            handlePrint={handlePrint}
            templateRef={templateRef}
          />
        </div>
        <button
          className="bg-blue-600 py-1 px-2 rounded-md text-white ml-12 text-sm"
          onClick={handlePrint}
        >
          Print Receipt
        </button>
      </div>
    </div>
  );
};

export default ConfirmCheckout;
