import React from "react";
import { useSelector } from "react-redux";
import { getActivePlan } from "../../../../utils/converter";

const FinalAmountSection: React.FC<{
  planType: string;
  getTotalPrice: any;
}> = ({ planType, getTotalPrice }) => {
  const { wareHouse } = useSelector((state: any) => state);

  const itemsList = wareHouse?.createdCart?.data?.list;
  const plan = wareHouse?.createdCart?.data?.subscriptionPlan;
  const discount = wareHouse?.createdCart?.data?.discount;

  const isSubscripitonPage =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";

  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
  );

  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const getTotalPrices = (list: any, subscritionPlan: number) => {
    return list
      ?.map(
        (item: any, _index: number) =>
          item?.payment?.amount +
          (item?.payment?.amount * item?.payment?.extendedWarranty) / 100
      )
      ?.reduce((total: any, price: any) => (total += parseInt(price)), 0);
  };

  return (
    <div className="flex justify-end border-t pt-2">
      <div className="grid grid-cols-2 w-fit  gap-x-2">
        <p>Total: </p>
        <span className="text-right">{`${
          isSubscripitonPage
            ? ActivePlan && ActivePlan.at(0)?.initialPayment?.toLocaleString()
            : getTotalPrices(itemsList, plan)?.toLocaleString()
        } RWF`}</span>
        <p>Discount:</p>
        <span className="text-right">{`(${discount?.discountPerc}%) ${(
          getTotalPrice(itemsList) *
          (discount?.discountPerc / 100)
        )?.toLocaleString()} RWF`}</span>

        <p className="flex items-center font-bold text-neutral-800 mt-5">
          Final amount:
        </p>
        <span className={`text-right font-bold text-slate-800 mt-5`}>{`${
          isSubscripitonPage
            ? ActivePlan && ActivePlan.at(0)?.initialPayment?.toLocaleString()
            : (
                getTotalPrices(itemsList, plan) -
                getTotalPrice(itemsList) * (discount?.discountPerc / 100)
              )?.toLocaleString()
        } RWF`}</span>
      </div>
    </div>
  );
};

export default FinalAmountSection;
