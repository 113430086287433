// import { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { useLocation } from "react-router-dom";
// import { authActions } from "../../store/auth";
// import { getActiveShop } from "../../utils/converter";
// import { getOneShopAction } from "../../store/shop/actions";
// import SideNav from "./SideNav";
// import "../buttons/button.css";
// import { getAllCartsAction } from "../../store/pos/actions";
// import HeaderShops from "../headerShops";
// import React from "react";
// import Header from "./Header";
// import { useMediaQuery } from "@mui/material";
// import CustomLayout from "../../apps/freelancerModule/layout/Layout"
// import { myLayoutActions } from "../../store/layout";
// const Layout = (props: any) => {
//   const dispatch = useDispatch();
//   const { auth, shop, appSettings, pos,layout } = useSelector((state: any) => state);
//   const location = useLocation();
//   const [activeShops, setActiveShops] = React.useState<any[]>([]);
//   const ishome = ["/dashboard"].includes(location.pathname);
//   const removeGlass =
//     location.pathname.includes("pos/sale/") || location.pathname === "/repair";
//   const shopInfo =
//     getActiveShop(auth?.user?.shop?.assigned)[
//       appSettings?.selectedShopNumber
//     ] || shop?.myShop;

//   useEffect(() => {
//     dispatch(authActions.setIsFetching(true));
//     // dispatch(authActions.setUser(store.get("user")));
//     dispatch(authActions.setIsFetching(false));
//   }, [auth.token, dispatch]);

//   useEffect(() => {
//     const user = localStorage.getItem("user");
//     if (user) {
//       auth?.token &&
//         getOneShopAction(auth.userShop?.shopId, auth?.token, true)(dispatch);
//     }
//   }, [auth?.token, dispatch]);

//   const isTablet = useMediaQuery("(max-width: 1270px)");
//   const [open, setOpen] = useState(isTablet ? false : true);

//   const shopId =
//     getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
//       ?.shop?._id || auth?.userShop?.shopId;

//   useEffect(() => {
//     auth?.token &&
//       getAllCartsAction(
//         auth?.token,
//         `?shop=${shopId}&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry`
//       )(dispatch);
//   }, [dispatch]);

//   React.useEffect(() => {
//     setActiveShops(getActiveShop(auth?.user?.shop?.assigned));
//   }, [auth?.user?.shop?.assigned]);
//   const handleSideNav = () => {
//     dispatch(myLayoutActions.setIsSideNavOpen(!layout.isSideNavOpen));
//   };
//   return (
//     <>
//      {['freelancer']?.includes(auth?.user?.profile) ? <CustomLayout/>:
//     <div className="flex">
//       <SideNav open={open} setOpen={setOpen} handleSideNav={handleSideNav}/>
//       <div className="flex flex-col w-full">
//         <Header open={open} data={pos} />
//         <main
//           className={`flex-grow px-5  ${
//             open ? "pl-[220px] xl:pl-[250px]" : "pl-[100px]"
//           } pt-24 bg-[#FAFAFB] overflow-auto max-h-screen`}
//         >
//           <div className="mb-5">
//             <HeaderShops selectedShop={activeShops} />
//           </div>
//           {props.children}
//         </main>
//       </div>
//     </div>
//    }
//     </>
//   );
// };

// export default Layout;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { authActions } from "../../store/auth";
import SideNav from "./SideNav";
import { myLayoutActions } from "../../store/layout";
import { useMediaQuery } from "@mui/material";
import { getAllCartsAction } from "../../store/pos/actions";
import { getActiveShop } from "../../utils/converter";
import { getOneShopAction } from "../../store/shop/actions";
import HeaderShops from "../headerShops";
import CustomLayout from "../../apps/freelancerModule/layout/Layout";
const Layout = (props: any) => {
  const { auth, shop, appSettings, pos, layout } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isEbm = location.pathname === "/ebm";
  const isKnowledgePage = location.pathname === "/knowledge";
  const isSetting = location.pathname === "/setting";
  const isVoaults = location.pathname === "/vaults";
  const isSettingInPath = location.pathname.includes("/setting");

  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    // dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleFullScreenClick = () => {
    if (elementRef.current) {
      elementRef.current.requestFullscreen();
    }
  };

  const handleSideNav = () => {
    dispatch(myLayoutActions.setIsSideNavOpen(!layout.isSideNavOpen));
  };
  const smallScreen = useMediaQuery("(max-width:767px)");
  const [activeShops, setActiveShops] = React.useState<any[]>([]);
  const ishome = ["/dashboard"].includes(location.pathname);
  const removeGlass =
    location.pathname.includes("pos/sale/") || location.pathname === "/repair";
  const shopInfo =
    getActiveShop(auth?.user?.shop?.assigned)[
      appSettings?.selectedShopNumber
    ] || shop?.myShop;

  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    // dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      auth?.token &&
        getOneShopAction(auth.userShop?.shopId, auth?.token, true)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const isTablet = useMediaQuery("(max-width: 1270px)");
  const [open, setOpen] = useState(isTablet ? false : true);

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  useEffect(() => {
    auth?.token &&
      getAllCartsAction(
        auth?.token,
        `?shop=${shopId}&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry`
      )(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    setActiveShops(getActiveShop(auth?.user?.shop?.assigned));
  }, [auth?.user?.shop?.assigned]);
  return (
    <div className="landScreenBack">
      <div
        className={` gap-8  w-full h-screen overflow-y-auto ${
          !props?.dashback && "landScreen"
        } `}
      >
        {["freelancer"]?.includes(auth?.user?.profile) ? (
          <CustomLayout />
        ) : (
          <div className="flex">
            <SideNav
              toggleSideNav={handleSideNav}
              selected={props?.selectedNav || 0}
              isOpen={layout.isSideNavOpen}
            />
            <div className="flex flex-col w-full">
              {/* <Header open={open} data={pos} /> */}
              <Header
                nav={props?.nav}
                title={props?.title || " Samphone"}
                handleFullScreenClick={handleFullScreenClick}
                isOpen={layout?.isSideNavOpen}
                setIsOpen={setIsOpen}
              />
              {/* <main
          className={`flex-grow px-5  ${
            layout?.isSideNavOpen ? "pl-[220px] xl:pl-[250px]" : "pl-[100px]"
          }  pt-24 bg-[#FAFAFB] overflow-auto max-h-screen`}
        >
          <div className="mb-5">
            <HeaderShops selectedShop={activeShops} />
          </div>
          {props.children}
        </main> */}
              <div
                className={
                  isKnowledgePage
                    ? `xpr-10 pt-[3rem] pb-4 h-[calc(100%-64px)] w-full ${
                        smallScreen && layout.isSideNavOpen && "pl-1 pt-[3rem]"
                      }  ${
                        !smallScreen && layout.isSideNavOpen
                          ? "pl-[210px]"
                          : !smallScreen && ` pl-[82px]`
                      }`
                    : `${isVoaults ? "" : "pb-4"}  h-[calc(100%-64px)] w-full ${
                        smallScreen
                          ? "px-4 pt-[3rem]"
                          : `${isVoaults ? "" : "pr-10"} pt-[3rem] pbs-4  ${
                              layout.isSideNavOpen
                                ? `${
                                    isSetting || isVoaults || isEbm
                                      ? "pl-[210px]"
                                      : "pl-[230px]"
                                  } `
                                : `${
                                    isSetting || isVoaults || isEbm
                                      ? "pl-[5rem]"
                                      : "pl-[6rem]"
                                  } `
                            }`
                      }`
                }
              >
                <div className="my-5 mt-10">
                 <HeaderShops selectedShop={activeShops} />
                </div>
                {props.children}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Layout;
