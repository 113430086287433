import { Radio } from "antd";

const PlansRadioGroup: React.FC<{
  onChange?: any;
  active?: any;
  disabled?: boolean;
}> = ({ onChange, active, disabled }) => {
  return (
    <div>
      <Radio.Group
        onChange={onChange}
        value={active}
        className="flex flex-wrap pb-2"
        disabled={disabled}
      >
        <Radio
          value={3}
          className={`w-44 h-14 border pl-4 mb-2 rounded-md border-gray-400 flex items-center xjustify-center ${
            active === 3 && "bg-[#7461e0] text-white"
          }`}
        >
          3 Months
        </Radio>
        {/* <Radio
          value={6}
          className={`w-44 h-14 border pl-4 mb-2 rounded-md border-gray-400 flex items-center xjustify-center ${
            active === 6 && "bg-[#7461e0] text-white"
          }`}
        >
          6 Months
        </Radio> */}
        {/* <Radio
          value={12}
          className={`w-44 h-14 border pl-4 mb-2 rounded-md border-gray-400 flex items-center xjustify-center ${
            active === 12 && "bg-[#7461e0] text-white"
          }`}
        >
          12 Months
        </Radio> */}
      </Radio.Group>
    </div>
  );
};

export default PlansRadioGroup;
