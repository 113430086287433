import React from "react";
import { Table, Tag, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { searchValue } from "../../utils/setColor";
import ScrollableFrame from "../layout/ScrollableFrame";
import { ReactComponent as RRALogo } from "../../assets/images/RRA_Logo.svg";
import RRAPopover from "../Modals/PricePopover";
import RRAForm from "../forms/RRAForm";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const TransactionsTable = (props: any) => {
  const { pos } = useSelector((state: any) => state);
  const [rRAOpen, setRRaOpen] = React.useState(-1);
  const [rowId, setRowId] = React.useState("");
  const navigate = useNavigate();

  const handleOpenChange = (index: any, id: any) => {
    setRRaOpen(index);
    setRowId(id);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, record) => (
        <>
          <p>{text || "N/A"}</p>
          <p>{record?.data?.cart?.customer?.phone || "N/A"}</p>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text, record) => (
        <Tooltip title={`${record.specification}`}>
          <div>
            <p>{text?.model}</p>{" "}
            {text?.discount ? (
              <Tag color="blue"> {`Disc: ${text?.discount}%`}</Tag>
            ) : null}
            {text?.extendedWarranty ? (
              <Tag color="cyan">{`Ext: ${text?.extendedWarranty}%`}</Tag>
            ) : null}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Total Amount (Frw)",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <Tag
          className="font-bold"
          color={
            record?.data?.payment?.amount +
              (record?.data?.payment?.amount *
                record?.data?.payment?.extendedWarranty) /
              100 -
              (record?.data?.payment?.amount *
                record?.data?.cart?.discount?.discountPerc) /
              100 ===
              record?.data?.cart?.payment?.reduce(
                (sum: number, a: any) => (sum = sum + a?.amount),
                0
              )
              ? "success"
              : record?.data?.payment?.amount +
                (record?.data?.payment?.amount *
                  record?.data?.payment?.extendedWarranty) /
                100 -
                (record?.data?.payment?.amount *
                  record?.data?.cart?.discount?.discountPerc) /
                100 >
                record?.data?.cart?.payment?.reduce(
                  (sum: number, a: any) => (sum = sum + a?.amount),
                  0
                )
                ? "error"
                : "cyan-inverse"
          }
        >
          {record?.data?.successPayment?.length > 0
            ? record?.data?.successPayment
              ?.reduce((sum: number, a: any) => (sum = sum + a?.amount), 0)
              ?.toLocaleString()
            : record?.data?.cart?.payment
              ?.reduce((sum: number, a: any) => (sum = sum + a?.amount), 0)
              ?.toLocaleString()}
        </Tag>
      ),
    },
    {
      title: "Transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text, record: any) => (
        <p
          className="text-sm cursor-pointer text-blue-700"
          onClick={() => navigate(`/pos/sale/${record.data.cart._id}`)}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          color={searchValue(record?.data?.status)}
          className="capitalize min-w-[50px] text-center"
        >
          {record?.data?.status}{" "}
        </Tag>
      ),
    },
    {
      title: "RRA Code",
      dataIndex: "rra",
      key: "rra",
      width: 100,
      render: (text, record, index: any) => (
        <>
          {record?.data?.receiptCode ? (
            <p>{record?.data?.receiptCode}</p>
          ) : (
            <RRAPopover
              title="RRA"
              handleOpen={() => handleOpenChange(index, record?.id)}
              open={rRAOpen === index}
              content={
                <RRAForm
                  date={props?.selectedDate}
                  itemId={rowId}
                  onCancel={setRRaOpen}
                  formCart={false}
                />
              }
              icon={<RRALogo className="w-6 h-6" />}
              disable={record?.data?.receiptCode ? true : false}
            />
          )}
        </>
      ),
    },
  ];

  const formData = pos?.transaction?.data?.map((el: any) => {
    return {
      id: el?._id,
      date_time: el?.updatedAt?.split("T")[0],
      agent: el?.createdBy?.names,
      model: {
        model:
          el?.shopStockItem?.requestedItem?.product?.product?.model +
          "~" +
          el?.shopStockItem?.requestedItem?.product?.product?.type,
        extendedWarranty: el?.payment?.extendedWarranty,
        discount: el?.cart?.discount?.discountPerc,
      },
      specification: `${el?.shopStockItem?.requestedItem?.product?.specification
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      amount: el?.payment?.amount,
      status: el?.status,
      barCode: el?.warehouseProduct?.serialNumber,
      transactionId: el?.cart?.cartId,
      customer: el?.cart?.customer?.name,
      data: el,
    };
  });

  return (
    <div className="overflow-x-auto">
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={pos?.transaction?.total}
        count={Math.ceil(pos?.transaction?.total / props?.limit)}
        isTable
      >
        <Table
          className="piece_sold"
          columns={columns}
          dataSource={formData}
          pagination={false}
          scroll={{ y: "65vh" }}
          loading={pos?.isFetching}
        />
      </ScrollableFrame>
    </div>
  );
};

export default TransactionsTable;
