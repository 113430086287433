import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TEChart } from "tw-elements-react";

export default function OverviewChart(): JSX.Element {
  const { auth,kpi } = useSelector((state: any) => state);
  const [backgroundColors, setBackgroundColors] = useState<string[]>([]);

  useEffect(() => {
    const currentDay = new Date().getDay();
    const colors = Array.from({ length: 7 }, (_, i) =>
      i === currentDay ? "#605BFF" : "#E7E6FF"
    );
    setBackgroundColors(colors);
  }, []);

  return (
    <TEChart
    type="bar"
    // style={{ width: "100%", height: "80rem" }} 
    data={{
      labels: kpi?.allEarning?.data?.results?.map((d: any) => d?.date),
      datasets: [
        {
          label: "Earnings",
          data: kpi?.allEarning?.data?.results?.map((d: any) => d?.revenue),
          backgroundColor: backgroundColors,
          borderRadius: 8,
          barPercentage: 0.5,
          barThickness: 40,
        },
      ],
    }}
    options={{
      scales: {
        x: {
          ticks: {
            font: {
              family: "Poppins",
            },
          },
        },
        y: {
          ticks: {
            font: {
              family: "Poppins",
            },
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const value = context.parsed.y;
              return `Earnings: Rwf ${value}`;
            },
          },
          titleFont: {
            family: "Poppins",
          },
          bodyFont: {
            family: "Poppins",
          },
        },
        legend: {
          labels: {
            font: {
              family: "Poppins",
            },
          },
        },
        datalabels: {
          formatter: (value: any, context: any) => {
            return `Rwf ${value}`;
          },
          font: {
            family: "Poppins",
          },
        },
      },
    }}
  />
  );
}


