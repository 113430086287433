import { Badge, Carousel, Checkbox, Col, Divider, Row, Skeleton } from 'antd';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getActivePrice } from '../../../utils/converter';
import { getOneRepairAction } from '../../../store/repair/actions';

interface DescriptionItemProps {
    title: string;
    content?:any;
  }
const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
    <div className="flex items-center justify-between w-full">
      <p className="text-sm md:text-base w-2/5 text-[#030229B2]">{title}</p>
      <span className="w-3/5 text-sm md:text-base text-[#030229]">{content}</span>
    </div>
  );
const RepairDetails = (props:any) => {
    const {repair,auth}=useSelector((state:any)=>state)
    const dispatch=useDispatch();
    useEffect(() => {
        auth?.token &&
          getOneRepairAction(props?.data?.data?._id, auth?.token)(dispatch);
      }, [auth?.token, props?.data?.data?._id, dispatch]);
    const itemPrice = getActivePrice(
        repair?.repairSearchResult?.cartItemId?.shopStockItem?.requestedItem?.product?.prices
      );
  return (
    <>
    {repair?.isFetching? <Skeleton />:
    <div className='text-black'>
          <Badge.Ribbon text=<h1 className="text-sm md:text-base pt-1">{`RWF ${itemPrice?.toLocaleString()}`}</h1> color="#FAAD14" className="w-[108px] h-[32px] text-center">
          <div className="border border-gray-100 p-3">
             <div className="flex flex-col sm:flex-row gap-10">
              <div className="bg-[#E5E5E5] w-full sm:w-[134px] h-[129px] p-2 rounded-md mt-8 sm:mt-0">
                        <Carousel autoplay className="w-full h-full">
                {repair?.repairSearchResult?.cartItemId?.shopStockItem?.requestedItem?.product?.product?.images?.map(
                  (im: any) => (
                    <div>
                      <img src={im} className="w-full h-full" alt=""/>
                    </div>
                  )
                )}
              </Carousel>
              </div>
              <div>
                  <h1 className="text-[#030229] text-sm md:text-xl font-medium">{repair?.repairSearchResult?.cartItemId?.shopStockItem?.requestedItem?.product?.product?.model}</h1>
                  <p><span className="text-[#030229B2] text-sm md:text-lg">Type:</span> <span className="text-[#030229] text-sm md:text-lg pl-1">{ repair?.repairSearchResult?.cartItemId?.shopStockItem?.requestedItem?.product?.product?.type}</span></p>
                  <p><span className="text-[#030229B2] text-sm md:text-lg">Brand:</span> <span className="text-[#030229] text-sm md:text-lg pl-1">{repair?.repairSearchResult?.cartItemId?.shopStockItem?.requestedItem?.product
                                ?.product?.brand}</span></p>
                  <div className="flex flex-col gap-2">
                            {repair?.repairSearchResult?.cartItemId?.shopStockItem?.requestedItem?.product?.specification?.map(
                             (data: any, index: number) => {
                              const regex = /\((.*?)\)/;
                              const match = data[0].match(regex);
            
                              if (match) {
                                const stringFromBracket = match[1];
            
                                if (
                                  !["type", "brand"].includes(
                                    stringFromBracket.toLowerCase()
                                  )
                                ) {
                                  return (
                                    <div className="flex text-[#030229] text-sm font-normal" key={index} >
                                      <p >
                                        <span >{`${stringFromBracket}: `}</span>
                                      </p>
                                      <p className="pl-1">{data[1]}</p>
                                    </div>
                                  );
                                }
                              }
            
                              return null;
                            }
                            )}
                          </div>
                  <p><span className="text-[#030229B2] text-sm md:text-lg">Extended Warranty:</span> <span className="text-[#030229] text-sm md:text-lg pl-1">{repair?.repairSearchResult?.cartItemId?.shopStockItem?.requestedItem?.product?.extendedWarranty}%</span></p>
                  
              </div>
  
             </div>
          </div>
        </Badge.Ribbon>
        <div>
             <p className="font-bold text-base md:text-lg" style={{ marginBottom: 10 }}>
          Customer
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Name"
              content={repair?.repairSearchResult?.cartItemId?.cart?.customer?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="National ID"
              content={repair?.repairSearchResult?.cartItemId?.cart?.customer?.nid
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Telephone"
              content={repair?.repairSearchResult?.cartItemId?.cart?.customer?.phone
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Email"
              content={repair?.repairSearchResult?.cartItemId?.cart?.customer?.email
              }
            />
          </Col>
        </Row>
        <Divider />
        <p className="font-bold text-base md:text-lg" style={{ marginBottom: 10 }}>
          Shop Details
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Name"
              content={repair?.repairSearchResult?.cartItemId?.cart?.shop?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Type"
              content={repair?.repairSearchResult?.cartItemId?.cart?.shop?.type
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Agent"
              content={repair?.repairSearchResult?.createdBy?.names
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Channel"
              content={repair?.repairSearchResult?.cartItemId?.cart?.shop?.channel?.name
              }
            />
          </Col>
        </Row>
        <Divider/>
        <p className="font-bold text-base md:text-lg" style={{ marginBottom: 10 }}>
          Payment Details
        </p>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Amount"
              content={repair?.repairSearchResult?.cartItemId?.payment?.amount
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title="Payment Mode"
              content={repair?.repairSearchResult?.cartItemId?.payment?.mode
              }
            />
          </Col>
        </Row>
        <Divider />
        <div className="bg-red-50 p-3">
        <div>
        <p
            className="site-description-item-profile-p font-bold text-base md:text-lg"
            style={{ marginBottom: 10 }}
          >
            Device Issues
          </p>
              {props?.data?.data?.deviceIssues?.map((d: any) => (
                <div className="space-x-1">
                  <Checkbox checked={d?.isFixed} />
                  <span>{d?.name}</span>
                </div>
              ))}
            </div>
          
          <Row>
            <Col span={24} className='mt-2'>
              <DescriptionItem
                title="Comment"
                content={repair?.repairSearchResult?.comment}
              />
            </Col>
          </Row>
        </div>

        <Divider />
        </div>
        </div>}
        </>
  )
}

export default RepairDetails