import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getSpecificationValue } from "../../../../utils/converter";
import { LoadingButton } from "@mui/lab";
import { handleNextStepAction } from "../redux/layout/actions";
import { createAccountAction } from "../../../../store/pos/actions";
import { updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";

const CustomerDetails = () => {
  const { wareHouse, auth ,pos} = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const customer = wareHouse?.createdCart?.data?.customer;
  const { cartId } = useParams();
  const navigate = useNavigate();

  const isSubmitted = wareHouse?.createdCart?.data?.isSubscriptionSubmitted;

  const planDetails = wareHouse?.createdCart?.data?.selectedPlan;
  const capacity = getSpecificationValue(
    planDetails?.pricelistItem?.specification,
    ["capacity (rom)", "rom", "capacity"]
  );

  const memory = getSpecificationValue(
    planDetails?.pricelistItem?.specification,
    ["memory", "memory (ram)", "ram", "memory(ram)"]
  );

  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;

  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;

  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handlePrevious = async () => {
    await handleNextStepAction(3)(dispatch);
  };

  const handleSendRequest = async () => {
    if (wareHouse?.createdCart?.data?.isSubscriptionSubmitted) {
      auth?.user?.profile==='freelancer'? navigate("/cart"): navigate("/sale");
    } else {
      const resPre = await updateShopStockCartDetailsAction(
        auth?.token,
        cartId,
        {
          isSubscriptionSubmitted: true, 
          subscriptionStatus: "ACCOUNT_REQUEST",
          status: "requested",
          payment: [
            {
              mode: "MoMo_Pay",
              amount: planDetails?.initialPayment,
            },
          ],
        }
      )(dispatch);
      if (resPre) {
        await createAccountAction(auth?.token, cartId as string)(dispatch);
        auth?.user?.profile==='freelancer'? navigate("/cart"): navigate("/sale");;
        notification.success({ message: "Request Sent Successfully" });
      }
    }
  };

  const amount =
    wareHouse?.createdCart?.data?.instalmentPlan === "daily"
      ? planDetails?.dailyInstalment
      : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
      ? planDetails?.weeklyInstalment
      : planDetails?.monthlyInstalment;

  return (
    <div className="max-w-4xl mx-auto p-4">
      {isOpen ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Personal Details */}
          <div>
            <h3 className="text-lg font-semibold mb-4">PERSONAL DETAILS</h3>
            <ul className="space-y-2">
              <li>
                <span className="font-medium text-lg">{customer?.name}</span>
              </li>
              <li>
                <span>📞 {`+${customer?.phone}`}</span>
              </li>
              <li>
                <span>📧 {customer?.email || "N/A"}</span>
              </li>
              <li>
                <span>ID: {customer?.nationalId}</span>
              </li>
            </ul>
            <a
              href="#e"
              className="text-blue-500 hover:underline mt-2 inline-block"
            >
              View other contacts (2)
            </a>

            {/* Address Details */}
            <h3 className="text-lg font-semibold mt-6">ADDRESS DETAILS</h3>
            <ul className="space-y-2">
              <li>
                <span>
                  Country:{" "}
                  <span className="font-medium">{address?.country}</span>
                </span>
              </li>
              <li>
                <span>
                  Province:{" "}
                  <span className="font-medium">{address?.province}</span>
                </span>
              </li>
              <li>
                <span>
                  District:{" "}
                  <span className="font-medium">{address?.district}</span>
                </span>
              </li>
              <li>
                <span>
                  Sector: <span className="font-medium">{address?.sector}</span>
                </span>
              </li>
              <li>
                <span>
                  Cell: <span className="font-medium">{address?.cell}</span>
                </span>
              </li>
              <li>
                <span>
                  Village:{" "}
                  <span className="font-medium">{address?.village}</span>
                </span>
              </li>
            </ul>
          </div>

          {/* Plan Details */}
          <div>
            <h3 className="text-lg font-semibold mb-4">PLAN DETAILS</h3>
            <ul className="space-y-2">
              <li>
                <span>
                  Device:{" "}
                  <span className="font-medium capitalize">
                    {planDetails?.pricelistItem?.product?.model}
                  </span>
                </span>
              </li>
              <li>
                <span>
                  Capacity (ROM):{" "}
                  <span className="font-medium">{capacity}</span>
                </span>
              </li>
              <li>
                <span>
                  Memory (RAM): <span className="font-medium">{memory}</span>
                </span>
              </li>
              <li>
                <span className="capitalize">
                  {`${wareHouse?.createdCart?.data?.instalmentPlan} Payment: `}
                  <span className="font-medium">
                    {" "}
                    {`${amount?.toLocaleString()} RWF`}
                  </span>
                </span>
              </li>
              <li>
                <span>
                  Plan Period:{" "}
                  <span className="font-medium">{`${planDetails?.duration} Months`}</span>
                </span>
              </li>
              <li>
                <span>
                  Payment Plan:{" "}
                  <span className="font-medium capitalize">
                    {wareHouse?.createdCart?.data?.instalmentPlan}
                  </span>
                </span>
              </li>
              <li>
                <span>
                  Initial Payment:{" "}
                  <span className="font-medium">
                    {`${planDetails?.initialPayment?.toLocaleString()} RWF`}
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="text-lg font-semibold mb-4">Documents</h3>
          <div className="flex flex-col sm:flex-row gap-6">
            <div>
              <label htmlFor="" className="font-medium text-lg">
                {`${customer?.name?.split(" ")[0]}'s Profile`}
              </label>
              <img
                src={userImage}
                alt=""
                className="h-48 object-contain w-full mt-2"
              />
            </div>
            <div>
              <label htmlFor="" className="font-medium pb-2 text-lg">
                National ID
              </label>
              <img
                src={nationalId}
                alt=""
                className="h-48 object-contain w-full mt-2"
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={`flex ${
          isSubmitted && wareHouse?.createdCart?.data?.status !== "requested"
            ? "justify-between sm:justify-center"
            : "justify-between"
        } mt-8`}
      >
        {!isSubmitted ? (
          <LoadingButton
            variant="outlined"
            onClick={handlePrevious}
            className="w-[100px] sm:w-[150px] text-lg uppercase"
          >
            Previous
          </LoadingButton>
        ) : (
          wareHouse?.createdCart?.data?.status === "requested" && <div></div>
        )}
        <button
          className="text-white bg-blue-500 p-2 rounded-full transition-all duration-300"
          onClick={handleToggle}
        >
          <IoIosArrowDown
            size={"25px"}
            className={`transition-transform duration-300 ${
              !isOpen ? "rotate-180" : "rotate-0"
            }`}
          />
        </button>
        {["pending", "requested"]?.includes(
          wareHouse?.createdCart?.data?.status
        ) ? (
          <LoadingButton
            variant="contained"
            loading={pos?.isFetching || wareHouse?.isUpdateFetching}
            onClick={handleSendRequest}
            className="w-[100px] sm:min-w-[150px] text-sm sm:text-lg uppercase"
          >
            {wareHouse?.createdCart?.data?.status === "requested"
              ? "Waiting for approval"
              : " Send request"}
          </LoadingButton>
        ) : (
          !isSubmitted && <div></div>
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;