import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  Drawer,
  message,
  Button,
  Form,
  notification,
  Table,
  Tag,
  Modal,
} from "antd";
import { CloseOutlined, DeleteFilled, SearchOutlined } from "@ant-design/icons";
import { ReactComponent as SearchItemImage } from "../assets/images/Scan to sell Option 2.svg";
import { ReactComponent as ScanImage } from "../assets/icons/material-symbols_barcode-scanner.svg";
import "../pages/dashboard/Inventory/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateTeamLeadTransfersActions,
  getSearchByEmiesActions,
  getTeamLeadActions,
  getTeamLeadTransfersActions,
  getTeamLeadTransfersDetailsActions,
  returnTransferAction,
  updateTeamLeadTransferAction,
  updateTransferAction,
} from "../store/inventory/actions";
import { getActiveShop } from "../utils/converter";
import BarcodeScanner from "./BarCodeScanner";
import SearchBar from "./SearchBar";
import QRCodeDrawer from "../pages/dashboard/Inventory/QRCodeDrawer";
import QRDrawer from "./QRDrawer";
import { myInventoryActions } from "../store/inventory";

const SearchProductTobeReturnedModal = (props: any) => {
  const { auth, appSettings, inventory } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [searchedProducts, setSearchedProducts] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [qrCodeData, setQrCodeData] = useState<any>([]);
  const [scannedCode, setScannedCode] = useState<string>();
  const [selectedTeamLeadId, setSelectedTeamLeadId] = useState<any>(null);
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const [isQrDrawerVisible, setIsQrDrawerVisible] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [searchResultsData, setSearchResultsData] = useState<any>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [form] = Form.useForm();
  const [emiData, setEmiData] = useState<any>([]);
  const [teamLeadForm] = Form.useForm();

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  useEffect(() => {
    if (isSearching && !inventory.isFetching) {
      if (emiData.wareHouseItem) {
        const existingItem = searchedProducts.some(
          (product) =>
            product?.wareHouseItem?.serialNumber ===
            emiData?.wareHouseItem?.serialNumber
        );

        if (!existingItem) {
          setSearchedProducts((prev) => [...prev, emiData]);
        }
      }
      setShowSearchResults(true);
      setIsSearching(false);
    }
  }, [props?.emiResult, isSearching]);

  const clearSearchData = () => {
    setSearchedProducts([]);
    setSelectedTeamLeadId(null);
    setSearchValue("");
    setScannedCode(undefined);
    setNewSearch(false);
    setShowSearchResults(false);
    setSearchResultsData(null);
    setIsSearching(false);
    setEmiData([]); // Clear the table data
    setVisible(false);
    setQrCodeData([]);
    setIsQrDrawerVisible(false);
    // Reset all forms
    form.resetFields();
    teamLeadForm.resetFields();
  };

  const handleReturnDevice = async () => {
    if (auth.token) {
      try {
        const warehouseItem = emiData.map(
          (product: any) => product.wareHouseItem?._id
        );
        const res = await returnTransferAction(
          props?.selectedTransferId,
          {
            warehouseItems: warehouseItem,
          },
          auth.token
        )(dispatch);

        if (res?.status === 200) {
          getTeamLeadTransfersDetailsActions(
            auth.token,
            props?.selectedTransferId
          )(dispatch);
          props?.setIsReturnModalOpen(false);
        }

        setQrCodeData(selectedTeamLeadId);
        setVisible(true);
        props?.setIsModalOpen(false);
        clearSearchData();
      } catch (error) {
        notification.error({ message: "Error generating QR code" });
      }
    }
  };

  const closeDrawer = () => {
    setVisible(false);
    setQrCodeData([]);
    clearSearchData();
  };

  const getCode = (code: any) => {
    setScannedCode(code);
    handleSearch(code);
  };

  useEffect(() => {
    if (auth?.token) {
      getTeamLeadActions(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const handleSearch = async (values: any) => {
    setIsSearching(true);
    const res = await getSearchByEmiesActions(
      auth?.token,
      `?serialNumber=${values?.query}&shopId=${shopId}`
    )(dispatch);

    if (res?.type && res?.data?.product) {
      const isDuplicate = emiData.some(
        (item: any) =>
          item?.wareHouseItem?._id === res.data.product?.wareHouseItem?._id
      );

      if (!isDuplicate) {
        setEmiData((prevData: any) => [...prevData, res.data.product]);
        form.resetFields();
      } else {
        message.warning("This device is already in the list");
      }
    }
  };

  const handleRemoveDevice = (deviceId: any) => {
    setEmiData((prevData: any) =>
      prevData.filter((item: any) => item?.wareHouseItem?._id !== deviceId)
    );
    message.success("Device removed successfully");
  };

  const handleChange = () => {
    setNewSearch(true);
  };

  // const closeQrDrawer = () => {
  //   setIsQrDrawerVisible(false);
  //   clearSearchData(); // Clear all data when closing QR drawer
  // };

  const handleDiscard = () => {
    clearSearchData();
    message.success("All data has been discarded");
    // If modal needs to be closed
    if (props?.setIsModalOpen) {
      props.setIsModalOpen(false);
    }
  };

  const handleDiscardWithConfirmation = () => {
    if (emiData.length > 0) {
      Modal.confirm({
        title: "Confirm Discard",
        content:
          "Are you sure you want to discard all items? This action cannot be undone.",
        okText: "Yes, Discard All",
        cancelText: "No, Keep Items",
        okButtonProps: {
          danger: true,
        },
        onOk: handleDiscard,
      });
    } else {
      handleDiscard();
    }
  };

  return (
    <div className="flex flex-col items-center py-4">
      <div className="flex flex-col md:flex-row w-full sm:w-[550px]  gap-3 items-center">
        <Form form={form} onFinish={handleSearch}>
          <Form.Item name="query" initialValue={scannedCode}>
            <SearchBar
              isLoading={inventory?.isFetching}
              scannedCode={newSearch ? "" : scannedCode}
              onChange={handleChange}
            />
          </Form.Item>
        </Form>

        <div className="flex gap-2 items-center cursor-pointer whitespace-nowrap pb-2">
          <BarcodeScanner getCode={getCode} />
        </div>
      </div>

      {emiData.length === 0 ? (
        <SearchItemImage />
      ) : (
        <>
          <div className="w-full sm:w-[550px] mb-6 px-4">
            <div className="rounded-lg w-full border border-gray-200">
              <div className="bg-gray-50 grid grid-cols-2 sm:grid-cols-4 py-3 px-4 font-medium text-xs sm:text-sm text-gray-600">
                <div>Model</div>
                <div>Description</div>
                <div>IMEI</div>
                <div>Action</div>
              </div>
              {emiData.map((item: any) => (
                <div
                  key={item?.wareHouseItem?._id}
                  className="grid grid-cols-2 sm:grid-cols-4 py-3 px-4 font-medium text-gray-600 text-xs sm:text-sm border-t bg-white"
                >
                  <div>{item?.requestedItem?.product?.product?.model}</div>
                  <div>
                    {item?.requestedItem?.product?.specification?.map(
                      (spec: any) => (
                        <div className="flex flex-row gap-1 sm:gap-3 text-xs sm:text-sm">
                          <strong>{spec[0]}:</strong> <span>{spec[1]}</span>
                        </div>
                      )
                    )}
                  </div>
                  <div>{item?.wareHouseItem?.serialNumber}</div>
                  <div>
                    <Button
                      type="link"
                      danger
                      onClick={() =>
                        handleRemoveDevice(item?.wareHouseItem?._id)
                      }
                    >
                      <DeleteFilled />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full sm:w-[500px] px-4">
            <div className="flex flex-col sm:flex-row justify-between gap-3 pt-8">
              <button
                className="px-6 py-2 border border-gray-300 rounded-md w-full sm:w-[300px] text-[#605BFF]"
                onClick={handleDiscardWithConfirmation}
                disabled={emiData.length === 0}
              >
                Discard All
              </button>
              <Button
                className="px-6 py-2 bg-[#605BFF] text-white rounded-md w-full sm:w-[300px] flex items-center justify-center"
                onClick={handleReturnDevice}
                disabled={inventory.isFetching}
              >
                Return
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchProductTobeReturnedModal;
