import { Carousel } from "antd";
import { getActivePrice, getTotalPrice } from "../../../utils/converter";
import React from "react";

const TransactionCartItem: React.FC<{ itemsList: any; discount: any }> = ({
  itemsList,
  discount,
}) => {
  return (
    <div>
      <p>Cart Summery:</p>
      <div>
        {itemsList?.map((item: any, _index: number) => (
          <div className="relative flex items-center p-2 my-2 rounded-md shadow-sm">
            <div className="w-16">
              <Carousel autoplay>
                {item?.shopStockItem?.requestedItem?.product?.product?.images?.map(
                  (im: any) => (
                    <div>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  )
                )}
              </Carousel>
            </div>
            <div>
              <p>
                {item?.shopStockItem?.requestedItem?.product?.product?.model}
              </p>
              <p>
                <b>Serial number: </b> {item?.warehouseProduct?.serialNumber}
              </p>
              {item?.payment?.extendedWarranty > 0 && (
                <p>
                  <b>Extended Wallanty: </b> {item?.payment?.extendedWarranty} %
                </p>
              )}
            </div>
            <div className="absolute top-1 right-2">
              {`${
                item?.payment?.amount +
                (item?.payment?.amount * item?.payment?.extendedWarranty) / 100
              } RWF`}
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 w-fit gap-x-2 float-right">
        <p>Total: </p>
        <span className="text-right">{`${getTotalPrice(
          itemsList
        )?.toLocaleString()} RWF`}</span>
        <p>Discount:</p>
        <span className="text-right">{`(${discount?.discountPerc}%) ${(
          getTotalPrice(itemsList) *
          (discount?.discountPerc / 100)
        )?.toLocaleString()} RWF`}</span>

        <p className="font-bold text-neutral-800 mt-5">Final amount:</p>
        <span className="text-right font-bold text-neutral-800  mt-5">{`${(
          getTotalPrice(itemsList) -
          getTotalPrice(itemsList) * (discount?.discountPerc / 100)
        )?.toLocaleString()} RWF`}</span>
      </div>
    </div>
  );
};

export default TransactionCartItem;
