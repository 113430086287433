import {
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Tag,
  notification
} from "antd";

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import RepairProductSearch from "../../../components/repair/RepairProductSearch";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import UploadComponent from "../../../components/forms/Upload";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getOneRepairAction,
  requestRepairAction,
  searchCartItemBySerialNumberAction,
  updateOneRepairAction
} from "../../../store/repair/actions";
import { useLocation } from "react-router";
import { searchValue } from "../../../utils/setColor";
import { updateCustomerAction } from "../../../store/customer/actions";
import dayjs from "dayjs";
import { myRepairActions } from "../../../store/repair";
import { getActiveShop } from "../../../utils/converter";
import "../Inventory/style.css";
const issues = [
  { name: "Screen Issue", isFixed: false },
  { name: "Charging Port Issue", isFixed: false },
  { name: "Network Issue (no sim)", isFixed: false },
  { name: "Connectivity Issue (WiFi)", isFixed: false },
  { name: "Connectivity Issue (Bluetooth)", isFixed: false },
  { name: "Audio (ear speaker)", isFixed: false },
  { name: "Audio (microphone)", isFixed: false },
  { name: "Audio (3.5mm jack)", isFixed: false },
  { name: "Buttons (Home Button)", isFixed: false },
  { name: "Buttons (Volume +/-)", isFixed: false },
  { name: "Software Issues", isFixed: false },
  { name: "Device corrupted", isFixed: false },
  { name: "⁠Stuck on MTN logo", isFixed: false },
  { name: "Not turning on", isFixed: false },
  { name: "Faulty speakers", isFixed: false },
  { name: "No service", isFixed: false },
  { name: "Heat", isFixed: false },
  { name: "Not charging", isFixed: false },
  { name: "Battery drain", isFixed: false },
  { name: "No Valid OS", isFixed: false }
];

interface DeviceIssues {
  name: string;
  isFixed: boolean;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const Repair = () => {
  const [form] = Form.useForm();
  const [DelivererForm] = Form.useForm();
  const [updateUserForm] = Form.useForm();
  const { TextArea } = Input;

  const { auth, repair, customer, appSettings } = useSelector(
    (state: any) => state
  );
  const [customerSignature, setCustomerSignature] = useState<any>([]);
  const [agentSignature, setAgentSignature] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomer, setIsCustomer] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cartItemId = searchParams.get("repairItem");
  const isView = cartItemId ? true : false;
  const repairStatus = repair?.repairSearchResult?.status?.toLowerCase();

  useEffect(() => {
    auth?.token &&
      getOneRepairAction(cartItemId as string, auth?.token)(dispatch);
  }, [auth?.token, cartItemId, dispatch, location]);

  const repairProduct = cartItemId
    ? repair?.repairSearchResult?.cartItemId
    : repair?.repairSearchResult;

  const onChange = (e: RadioChangeEvent) => {
    setIsCustomer(e.target.value);
  };
  console.log("repairProduct",repairProduct)
  const onFinish = async (values: any) => {
    const payload = {
      ...values,
      cartItemId: repairProduct?._id,
      dateReceivedByAgent: new Date(),
      isNotify: true,
      agentSignature: agentSignature[0]?.response?.data?.secure_url,
      customerSignature: customerSignature[0]?.response?.data?.secure_url,
      shop: getActiveShop(auth?.user?.shop?.assigned)[
        appSettings?.selectedShopNumber
      ]?.shop?._id,
      notification: {
        action: "Request For Device Repair",
        role: ["admin", "dev", "technician"],
        message: `Device to be repaired: ${repairProduct?.warehouseProduct?.serialNumber}`,
        title: ``
      }
    };
    auth?.token && (await requestRepairAction(auth?.token, payload)(dispatch));
    form.resetFields();
    navigate("/repair");
  };

  const updateCustomerData = async (values: any) => {
    await updateCustomerAction(
      auth?.token,
      repairProduct?.cart?.customer?._id,
      values
    )(dispatch);
    auth?.token &&
      (await searchCartItemBySerialNumberAction(
        auth?.token,
        repair?.repairSearchResult?.warehouseProduct?.serialNumber
      )(dispatch));
    setIsModalOpen(false);
  };

  const onAddDeliverer = async (values: any) => {
    console.log("Deliverer", values);
    const { date } = values;
    const dateString = dayjs(date).format("YYYY-MM-DD");
    console.log("Selected Date String:", dateString);
    updateOneRepairAction(
      cartItemId as string,
      { status: "transit-in", delivererIn: { ...values, date: dateString } },
      auth?.token
    )(dispatch);
  };

  const updateStatus = (status: string) => {
    updateOneRepairAction(
      cartItemId as string,
      { status: status },
      auth?.token
    )(dispatch);
  };
  const handleCloseNIDModal = () => {
    if (!repairProduct?.cart?.customer?.nid) {
      return notification.warning({ message: "add customer National ID" });
    }
    return setIsModalOpen(false);
  };
  useEffect(() => {
    if (repairProduct && !repairProduct?.cart?.customer?.nid) {
      setIsModalOpen(true);
    }
  }, [repairProduct, repairProduct?.cart?.customer?.nid]);

  return (
    <div className="flex flex-col lg:flex-row h-full leading-none gap-x-[0.7vw] text-[#030229] ">
      <div className="flex flex-col w-full lg:w-[65%] gap-y-[0.7vw] overflow-y-auto">
        <div
          className={`${repairProduct ? "" : "h-full"} bg-white p-2 rounded-lg`}
        >
          <RepairProductSearch isView={isView} />
        </div>
        {repairProduct && (
          <>
            {/* Customer details */}
            <div className="bg-white space-y-3 p-2 rounded-md text-[#030229] ">
              <div className="flex justify-between">
                <h2 className="text-[#03022980] text-base md:text-lg">Customer Details</h2>
                {!isView && (
                  <LoadingButton
                    variant="contained"
                    style={{ padding: "4px" }}
                    sx={{ backgroundColor: "#605BFF" }}
                    loading={repair?.isFetching}
                    onClick={() => setIsModalOpen(true)}
                  >
                    Update National ID
                  </LoadingButton>
                )}
              </div>
              <Divider className="!m-1 !p-0" />
              <div className="flex gap-x-4 text-[#03022980]">
                <div className="w-28 h-28 rounded-full">
                  <img
                    src={repairProduct?.cart?.customer?.picture}
                    alt=""
                    className=" w-full h-full rounded-full"
                  />
                </div>
                <div className="flex flex-col justify-between">
                  <p>
                    <p className="text-[#03022980] text-base md:text-lg">Name: </p>
                    <span className="text-[#030229] text-base md:text-lg">{repairProduct?.cart?.customer?.name}</span>
                  </p>
                  <p>
                    <p  className="text-[#03022980] text-base md:text-lg">Email: </p>
                    <span className="text-[#030229] text-base md:text-lg"> {repairProduct?.cart?.customer?.email}</span>
                  </p>
                  <p>
                    <p className="text-[#03022980] text-base md:text-lg">National ID: </p>
                    <span className="text-[#030229] text-base md:text-lg">  {repairProduct?.cart?.customer?.nid}</span>
                  </p>
                  <p>
                    <p className="text-[#03022980] text-base md:text-lg">Phone: </p>
                    <span className="text-[#030229] text-base md:text-lg"> {repairProduct?.cart?.customer?.phone}</span>
                  </p>
                </div>
              </div>
            </div>

            {/* Customer details */}
            {repair?.repairSearchResult?.returnedBy && (
              <div className="bg-white space-y-3 p-2 rounded-md">
                <div className="flex justify-between">
                  <h2 className="font-semibold">Returned by</h2>
                </div>
                <Divider className="!m-1 !p-0" />
                <div className="flex gap-x-2">
                  <div className="flex flex-col justify-between">
                    <p>
                      <b className="text-[#03022980] text-base md:text-lg">Name: </b>
                     <span className="text-[#030229] text-sm sm:text-base md:text-lg">{repair?.repairSearchResult?.returnedBy?.names}</span> 
                    </p>
                    <p>
                      <b className="text-[#03022980] text-base md:text-lg">National ID: </b>
                      <span className="text-[#030229] text-base md:text-lg">{repair?.repairSearchResult?.returnedBy?.nid}</span> 
                    </p>
                    <p>
                      <b className="text-[#03022980] text-base md:text-lg">Phone: </b>
                      <span className="text-[#030229] text-base md:text-lg"> {repair?.repairSearchResult?.returnedBy?.phone}</span>
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Shop Details */}
            <div className="bg-white space-y-3 p-2 rounded-md">
              <h2 className="font-semibold">Shop Details</h2>
              <Divider className="!m-1 !p-0" />
              <div className="flex gap-x-4">
                <div>
                  <img
                    src={repairProduct?.shopStockItem?.shop?.channel?.logo}
                    alt=""
                    className="w-28 rounded-full"
                  />
                </div>
                <div className="flex flex-col justify-between">
                  <p>
                    <p className="text-[#03022980] text-base md:text-lg">Name: </p>
                    <span className="text-[#030229] text-base md:text-lg">{repair?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />: repairProduct?.shopStockItem?.shop?.name}</span>
                  </p>
                  <p>
                    <p className="text-[#03022980] text-base md:text-lg">Region: </p>
                    <span className="text-[#030229] text-base md:text-lg">  {repair?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />: repairProduct?.shopStockItem?.shop?.region}</span>
                  </p>
                  <p>
                    <p className="text-[#03022980] text-base md:text-lg">Type: </p>
                    <span className="text-[#030229] text-base md:text-lg"> {repair?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />: repairProduct?.shopStockItem?.shop?.type}</span>
                  </p>
                  <p>
                    <p className="text-[#03022980] text-base md:text-lg">Merchant Name: </p>
                    <span className="text-[#030229] text-base md:text-lg"> {repair?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />: repairProduct?.shopStockItem?.shop?.merchantName}</span>
                  </p>
                  <Divider className="!mb-1 !mt-2 !p-0" />
                  <p>
                    <p className="text-[#03022980] text-base md:text-lg">Channel: </p>
                    <span className="text-[#030229] text-base md:text-lg">{repair?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />: repairProduct?.shopStockItem?.shop?.channel?.name}</span>
                  </p>
                </div>
              </div>
            </div>

            {/* Payment Details */}
            <div className="bg-white space-y-3 p-2 rounded-md text-[#03022980]">
              <h2 className="font-semibold">Payment Details</h2>
              <Divider className="!m-1 !p-0" />
              <div className="flex gap-x-4 text-[#03022980]">
                <div>
                  <img
                    src={repairProduct?.shopStockItem?.shop?.channel?.logo}
                    alt=""
                    className="w-28 rounded-full"
                  />
                </div>
                {repairProduct?.cart?.payment?.map(
                  (payment: any, index: number) => (
                    <div className="flex flex-col justify-between text-[#03022980]">
                      <p>
                        <b>{payment?.mode?.split("_")?.join(" ")}: </b>
                        {`RWF ${payment?.amount?.toLocaleString()}`}
                      </p>
                    </div>
                  )
                )}
                <div>{/* hereeee! */}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex-1 p-5 bg-white rounded-lg overflow-auto mt-5 lg:mt-0">
        <div className="relative flex justify-end">
          {repair?.repairSearchResult?.status && (
            <Tag
              color={searchValue(repair?.repairSearchResult?.status)}
              className="capitalize min-w-[50px] text-center"
            >
              {repair?.repairSearchResult?.status}
            </Tag>
          )}
        </div>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{ comment: repair?.repairSearchResult?.comment }}
          scrollToFirstError
          className="flesx justify-scenter flex-col w-full customForm" 
          layout="vertical"
        >
          {!repair?.isFetching && (
            <Form.Item
              name="deviceIssues"
              className="w-full "
              label=<h1 className="text-base font-medium text-[#03022980] w-full ">
                Select issue(s) from the list below
              </h1>
              rules={[
                { required: true, message: "Please select at least one issue" }
              ]}
              initialValue={repair?.repairSearchResult?.issues}
            >
              <Checkbox.Group className="flex flex-col  w-full">
                {(repair?.repairSearchResult?.deviceIssues || issues).map(
                  (issue: DeviceIssues) => (
                    <Checkbox
                      key={issue.name}
                      value={{ name: issue.name }}
                      className="!m-0 px-2 text-[#03022980] text-sm py-1"
                      checked={issue.isFixed}
                      disabled={cartItemId ? true : false}
                    >
                      {issue.name}
                    </Checkbox>
                  )
                )}
              </Checkbox.Group>
            </Form.Item>
          )}

          {/* returned by */}
          <>
            <div className="flex mb-3">
              <h2 className="font-medium text-sm text-[#03022980]">
                Returned by
              </h2>
            </div>
            <Divider className="!m-1 !p-0" />
            <Radio.Group onChange={onChange} value={isCustomer}>
              <Radio
                value={true}
                className="text-[#03022980]"
                style={{ fontSize: "10px" }}
              >
                Customer
              </Radio>
              <Radio
                value={false}
                className="text-[#03022980]"
                style={{ fontSize: "10px" }}
              >
                Someone else
              </Radio>
            </Radio.Group>
            {!isCustomer && (
              <>
                <div className=" w-full ">
                  {/* <div className="w-1/2"> */}
                  <Form.Item
                    name={["returnedBy", "names"]}
                    label="Names"
                    className="w-full"
                    rules={[{ required: true, message: "Please add names" }]}
                  >
                    <Input className="h-[42px]" />
                  </Form.Item>
                  {/* </div> */}
                  {/* <div> */}
                  <Form.Item
                    name={["returnedBy", "phone"]}
                    label="Phone"
                    className="w-full"
                    rules={[{ required: true, message: "Please add phone" }]}
                  >
                    <Input className="h-[42px]" />
                  </Form.Item>

                  <Form.Item
                    name={["returnedBy", "nid"]}
                    label="National ID"
                    rules={[
                      { required: true, message: "Please add national ID" }
                    ]}
                  >
                    <Input className="w-full h-[42px]" />
                  </Form.Item>
                  {/* </div> */}
                </div>
              </>
            )}
          </>
          {/* end of returned by */}

          {/* signatures */}
          <>
            <div className="flex my-3">
              <h2 className="text-base font-medium text-[#03022980]">
                Signatures
              </h2>
            </div>
            <Divider className="!m-1 !p-0" />
            {!cartItemId && (
              <Form.Item
                name="customerSignature"
                label=<p className="text-[#03022980] text-[12px]">
                  Customer signature
                </p>
                rules={[
                  {
                    required: true,
                    message: "Please upload customer's signature"
                  }
                ]}
              >
                <div className="w-full">
                  <div className="">
                    <p className="text-base  text-[#03022980]">
                      Please{" "}
                      <a
                        className={"text-violet-600"}
                        href="https://www.signwell.com/online-signature/draw/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {"CLICK HERE!!"}
                      </a>{" "}
                      to capture and save your signature. When finished, please
                      upload the image below
                    </p>
                    <UploadComponent
                      setPictures={setCustomerSignature}
                      isCard={true}
                      limit={1}
                    />
                  </div>
                </div>
              </Form.Item>
            )}

            {cartItemId && (
              <div>
                <p>Customer's Signature</p>
                <div className="w-28 h-28 rounded-lg p-1">
                  <img
                    src={repair?.repairSearchResult?.customerSignature}
                    alt="Agent's signature"
                    className="w-full"
                  />
                </div>
              </div>
            )}

            {!cartItemId && (
              <Form.Item
                name="agentSignature"
                label=<p className="text-[#03022980] text-[12px]">
                  Agent signature
                </p>
                rules={[
                  {
                    required: true,
                    message: "Please upload agent's signature"
                  }
                ]}
              >
                <div className="w-full">
                  <div className="">
                    <p>
                      Please{" "}
                      <a
                        className={"text-violet-600"}
                        href="https://www.signwell.com/online-signature/draw/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {"CLICK HERE!!"}
                      </a>{" "}
                      to capture and save your signature. When finished, please
                      upload the image below
                    </p>
                    <UploadComponent
                      setPictures={setAgentSignature}
                      isCard={true}
                      limit={1}
                    />
                  </div>
                </div>
              </Form.Item>
            )}

            {cartItemId && (
              <div>
                <p>Agent's Signature</p>
                <div className="w-28 h-28 rounded-lg p-1">
                  <img
                    src={repair?.repairSearchResult?.agentSignature}
                    alt="Agent's signature"
                    className="w-full"
                  />
                </div>
              </div>
            )}
          </>
          {/* the end of signatures */}

          <Form.Item
            name="comment"
            label="comment"
            rules={[
              {
                required: true,
                message: "Please add some comments"
              }
            ]}
            initialValue={repair?.repairSearchResult?.comment}
          >
            <TextArea
              rows={4}
              disabled={isView}
              defaultValue={repair?.repairSearchResult?.comment}
            />
          </Form.Item>

          {!cartItemId && (
            <div className="flex justify-end gap-x-2 mr-5 mt-2 !h-4 box-border">
              <Form.Item {...tailFormItemLayout}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  style={{ padding: "4px", backgroundColor: "#605BFF" }}
                  loading={repair?.isFetching}
                >
                  Submit
                </LoadingButton>
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <LoadingButton
                  // type="submit"
                  style={{ padding: "4px", backgroundColor: "#605BFF" }}
                  variant="contained"
                  loading={repair?.isFetching}
                  onClick={() => form.resetFields()}
                >
                  Clear
                </LoadingButton>
              </Form.Item>
            </div>
          )}
        </Form>

        {/* Deliverer in */}
        {["send-to-office"].includes(repairStatus) && (
          <div className="w-full">
            <div className="flex">
              <h2 className="font-semibold text-xl">Will Be Delivered By</h2>
            </div>
            <Divider className="!m-1 !p-0" />
            <>
              <Form
                // {...formItemLayout}
                form={DelivererForm}
                name="register"
                onFinish={onAddDeliverer}
                initialValues={{
                  name: repair?.repairSearchResult?.delivererIn?.name,
                  phone: repair?.repairSearchResult?.delivererIn?.phone,
                  email: repair?.repairSearchResult?.delivererIn?.email,
                  company: repair?.repairSearchResult?.delivererIn?.company
                }}
                scrollToFirstError
                className="flex justify-center flex-col w-full"
                layout="vertical"
              >
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Please add name" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[{ required: true, message: "Please add phone" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: "Please add email" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="company"
                  label="Company"
                  rules={[{ required: true, message: "Please add company" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="date"
                  label="Date"
                  rules={[{ required: true, message: "Please add date" }]}
                >
                  <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
                <Form.Item>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    style={{ padding: "4px", backgroundColor: "#605BFF" }}
                    loading={repair?.isFetching}
                  >
                    Submit
                  </LoadingButton>
                </Form.Item>
              </Form>
            </>
          </div>
        )}
        {/* end of deliverer in */}
        <div className="flex items-center gap-2 mt-12 xl:mt-0">
          {(["complete"].includes(repairStatus) ||
            repair?.repairSearchResult?.repairedAt === "shop") && (
            <div>
              <LoadingButton
                type="submit"
                variant="contained"
                className="!px-4"
                loading={repair?.isFetching}
                onClick={() => updateStatus("done")}
              >
                Mark As Done
              </LoadingButton>
            </div>
          )}
          {["transit-out"].includes(repairStatus) && (
            <div>
              <LoadingButton
                type="submit"
                variant="contained"
                className="!px-4"
                loading={repair?.isFetching}
                onClick={() => updateStatus("complete")}
              >
                Mark As Complete
              </LoadingButton>
            </div>
          )}
          {!["done"].includes(repairStatus) && (
            <div>
              <a
                href={`intent://samphoneprinter.com/repair=${cartItemId}#Intent;scheme=https;package=com.samphoneprinter;end`}
                className="bg-[#605BFF] text-white py-2 px-4 rounded-md "
              >
                Print QR Ticket
              </a>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onCancel={handleCloseNIDModal}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        title={"Update National ID"}
      >
        <Form
          {...formItemLayout}
          form={updateUserForm}
          name="updateUserData"
          onFinish={updateCustomerData}
          initialValues={{}}
          scrollToFirstError
          className="flex justify-center flex-col w-full"
        >
          <Form.Item
            name="nid"
            label="National ID"
            rules={[{ required: true, message: "Please add national ID" }]}
          >
            <Input />
          </Form.Item>

          <div className="flex w-1/2 gap-x-4 m-auto ">
            <Form.Item>
              <LoadingButton
                type="submit"
                variant="contained"
                style={{ padding: "4px", backgroundColor: "#605BFF" }}
                loading={customer.isFetching}
              >
                Submit
              </LoadingButton>
            </Form.Item>
            <Form.Item>
              <LoadingButton
                type="submit"
                variant="contained"
                style={{ padding: "4px", backgroundColor: "#605BFF" }}
                className=" bg-[#605BFF] w-[150px]"
                onClick={() => {
                  dispatch(myRepairActions.setRepairSearchResult(null));
                  setIsModalOpen(false);
                }}
                loading={customer.isFetching}
              >
                Clear Search
              </LoadingButton>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Repair;
